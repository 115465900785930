import * as moment from 'moment/min/moment-with-locales';

import { capitalize, translationStore, labels, userStore } from '..';

moment.locale('it');

export const resetMomentLocale = (language) => {
  moment.locale(language);
}

export function currentDate(sum = 0) {
  const date = moment(new Date());
  while (sum > 0) {
    date.add(1, 'days');
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      sum -= 1;
    }
  }
  return date;
}

export function currentYear() {
  return moment(currentDate()).format('YYYY');
}

export function dayFullMonth(date) {
  // d month
  return date ? capitalize(moment(date).format('D MMMM')) : date;
}

export function dayFullMonthFullYear(date) {
  // dd month yyyy
  return date ? capitalize(moment(date).format('DD MMMM YYYY')) : date;
}

export function dayMonthFullYear(date, _ = '/') {
  // dd/mm/yyyy
  return date ? moment(date).format(`DD${_}MM${_}YYYY`) : date;
}

export function dayMonthYear(date, _ = '/') {
  // dd/mm/yy
  return date ? moment(date).format(`DD${_}MM${_}YY`) : date;
}

export function fullDateTime(date, _ = '/', __ = '-', ___ = ':') {
  // dd/mm/yy hh:mm
  return date ? moment(date).format(`DD${_}MM${_}YY ${__} HH${___}mm`) : date;
}

export function formatDateForBE(date) {
  const d = moment(date);
  // eslint-disable-next-line
  return date ? d.hour() === 0
    ? d.add(12, 'hours')._d.toISOString()
    : d.hours(12).minutes(0).seconds(0).milliseconds(0)._d.toISOString() : date;
}

export function buildDateInterval(start, end) {
  const startDate = dayMonthFullYear(start);
  const endDate = dayMonthFullYear(end);
  return endDate ? `${translationStore.get(labels.DAL)} ${startDate} ${translationStore.get(labels.AL).toLowerCase()} ${endDate}` : '';
}

export function checkValidDate(date) {
  return moment(formatDateForBE(date)).diff(formatDateForBE(moment(new Date()))) >= 0;
}

export function getMonth(date) {
  return capitalize(moment(date).format('MMMM'));
}

export function getMonthYear(date) {
  return capitalize(moment(date).format('MMMM YYYY'));
}

