import React from 'react';
import { observer } from 'mobx-react';

import { navigationStore } from '../../commons';

const Loading = observer(({ full, size }) => {
  const resize = (size && `${size}%`) || '100%';
  return (
    <div>
      <div
        style={{ width: resize, height: resize }}
        className={`lds-cube${full ? ' full' : ''}${navigationStore.isToggled ? ' toggled' : ''}`}
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
});

export default Loading;
