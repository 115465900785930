import React from 'react';
import { Label, Input as Inpt, FormGroup } from 'reactstrap';

import { inputTypes } from '../../../commons';
import { Image } from '../../index';
import Multiselect from './Multiselect';
import AutoComplete from './AutoComplete';
import Radio from './Radio';
import Checkbox from './Checkbox';
import Select from './Select';
import Switch from './Switch';
import DatePicker from './DatePicker';
import FileUploader from './FileUploader';

import google from '../../../assets/images/logos/google.png';

const Input = (props) => {
  let input;
  let labelBefore = true;
  const label = props.label && <Label>{`${props.label}${props.required ? ' *' : ''}`}</Label>;
  const sublabel = props.sublabel && <Label className="sublabel">{props.sublabel}</Label>;

  switch (props.type) {
    case inputTypes.MULTISELECT:
      input = (
        <Multiselect {...props} />
      );
      break;
    case inputTypes.AUTOCOMPLETE:
      input = (
        <AutoComplete {...props} />
      );
      break;
    case inputTypes.RADIO:
      input = (
        <Radio
          {...props}
          className="form-radio"
        />
      );
      break;
    case inputTypes.CHECKBOX:
      input = (
        <Checkbox {...props} />
      );
      labelBefore = false;
      break;
    case inputTypes.SELECT:
      input = (
        <Select
          {...props}
          selected={props.value}
        />
      );
      break;
    case inputTypes.SWITCH:
      input = (
        <Switch {...props} />
      );
      break;
    case inputTypes.DATE:
      input = (
        <DatePicker {...props} />
      );
      break;
    case inputTypes.FILE:
      input = (
        <FileUploader {...props} />
      );
      break;
    case inputTypes.NUMBER:
    case inputTypes.TEXT:
    case inputTypes.TEXTAREA:
      input = (
        <Inpt
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          className={`${props.half ? 'half ' : ''}${props.isValid === false ? 'is-invalid  ' : ''}${props.className ? props.className : ''}`}
          min={props.min || 0}
          max={props.max}
          step={props.step}
          style={props.half ? { width: `${props.half}%` } : undefined}
        />
      );
      break;
    default:
      break;
  }

  if (label) {
    return (
      <FormGroup
        className={`form-${props.type}${props.isValid === false ? ' invalid-group' : ''}`}
        check={!labelBefore}
      >
        {labelBefore && label}
        {sublabel}
        {input}
        {!labelBefore && label}
        {props.disclaimerLabel ? (
          <div className={`input-disclaimer${props.isValid ? '' : ' invalid'}`}>
            {props.disclaimerLabel}
          </div>
        ) : ''}
        {props.validationLabel ? (
          <div className={`invalid${props.isValid ? ' hidden' : ''}`}>
            {props.validationLabel}
          </div>
        ) : ''}
        {props.poweredByGoogle && (
          <Image src={google} />
        )}
      </FormGroup>
    );
  }
  return input;
};

export default Input;
