import React, { Component } from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import Autocomplete from 'react-autocomplete';
import { CloseOutlined } from '@material-ui/icons';

import { apiGeotool, sequentialGet, sequentialGeotoolGet, colors, labels, translationStore } from '../../../commons';
import { Button } from '../..';

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      valueChanged: false,
      items: [],
      loading: false,
      sessionId: props.detailUrl ? this.generateSessionId() : '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.valueChanged
      && (nextProps.value && nextProps.value !== prevState.value)) {
      return ({ value: nextProps.value });
    }
    return null;
  }

  generateSessionId = () => Math.random().toString(36).substring(7);

  getItems = (value) => {
    this.setState({
      value,
      valueChanged: true,
      items: [],
      loading: value.length > 0
    });

    const {
      props: { detailUrl, searchUrl, showSeeAll, onSearch },
      state: { sessionId },
    } = this;

    const get = detailUrl ? sequentialGeotoolGet : sequentialGet;

    get(`${searchUrl}${value}${sessionId ? `&sessionId=${sessionId}` : ''}`)
      .then((res) => {
        let items = res.data.data;
        if (showSeeAll && items.length) {
          items = [{
            source: 0,
            title: translationStore.get(labels.VEDI_TUTTI),
          }, ...items];
        } else if (!items.length) {
          items.push({
            source: null,
            title: translationStore.get(labels.NO_DATA_RICERCA),
          });
        }
        this.setState({
          items: items.map((item, i) => ({ ...item, key: `item-${i}` })),
          loading: false,
        });
        if (onSearch) {
          onSearch(value);
        }
      })
      .catch(() => { });
  }

  onSelectItem = (_, item) => {
    const {
      props: { detailUrl, onSelect, items, oldItem, getItemValue, clearInput },
      state: { sessionId, items: stateItems },
    } = this;

    if (detailUrl) {
      apiGeotool
        .get(`${encodeURI(detailUrl.replace('{addressId}', item.id))}${sessionId ? `?sessionId=${sessionId}` : ''}`)
        .then((res) => {
          onSelect({ ...res.data, id: null }, oldItem, items);
          this.setState({ sessionId: this.generateSessionId() });
        });
    } else {
      onSelect(item, oldItem, items || stateItems);
    }
    this.setState({
      value: clearInput ? '' : getItemValue(item),
      items: []
    });
  };

  render() {
    const {
      state: { value, items, loading },
      props: {
        className,
        getItemValue,
        shouldItemRender,
        placeholder,
        disabled,
        appendLabel,
        removeValue,
        icon,
        hideClearEmptyItems,
        hasCustomRender,
        renderItem,
        inputRef,
        onClickUndo,
      },
      getItems,
      onSelectItem,
    } = this;

    return (
      <InputGroup className={className}>
        <Autocomplete
          wrapperStyle={{ position: 'relative', flex: '1 1 auto', width: '1%', marginBottom: '0' }}
          value={value}
          items={items}
          getItemValue={getItemValue}
          onChange={e => getItems(e.target.value)}
          onSelect={onSelectItem}
          shouldItemRender={shouldItemRender}
          renderItem={(item, isHighlighted) => {
            const val = getItemValue(item);
            return (
              <div
                key={item.key}
                style={{ background: isHighlighted ? colors.MAIN_LIGHT_40 : 'white' }}
              >
                {hasCustomRender ? renderItem(item) : val}
              </div>
            );
          }}
          renderMenu={children => (
            <div className={children.length ? 'autocomplete-menu' : ''}>
              {children}
            </div>
          )}
          inputProps={{
            // onBlur: () => this.setState({ items: [] }),
            onFocus: (e) => {
              if (value !== e.target.value) {
                getItems(e.target.value);
              }
            },
            placeholder,
            disabled,
          }}
          ref={(i) => {
            if (inputRef) {
              inputRef(i);
            }
          }}
        />
        <InputGroupAddon addonType="append">
          {appendLabel
            // eslint-disable-next-line max-len
            || ((((hideClearEmptyItems && value) || (!hideClearEmptyItems && !disabled)) || onClickUndo) && (
              <Button
                color="link icon"
                icon={icon || CloseOutlined}
                onClick={() => {
                  this.setState({ value: '', items: [] });
                  if (removeValue) {
                    removeValue(value);
                  }
                  if (onClickUndo) {
                    onClickUndo();
                  }
                }}
                loading={loading}
                withLoading
              />
            ))}
        </InputGroupAddon>
      </InputGroup>
    );
  }
}
