import { observable, action, computed } from 'mobx';
import { DashboardOutlined, PermMediaOutlined, FolderOutlined, Build, EventOutlined, PeopleAltOutlined, ShoppingCartOutlined } from '@material-ui/icons';

import { routes, labels, getRoute, isDevelopment, documentStore } from '..';


export default class NavigationStore {
  @observable pages = [
    {
      route: routes.HOMEPAGE,
      label: labels.HOMEPAGE,
      path: './Homepage',
      icon: DashboardOutlined,
      active: true,
    },
    {
      label: labels.MATERIALI,
      icon: PermMediaOutlined,
      open: false,
      children: [
        {
          route: routes.MATERIALI_GOTOMARKET,
          label: labels.GOTO_MARKET,
          path: './campaigns/CampaignsCategories',
        },
        {
          route: routes.MATERIALI_FORMAZIONERETE,
          label: labels.FORMAZIONE_RETE,
          path: './campaigns/CampaignsCategories',
          hideReferral: true,
        },
        {
          // TODO: LEVA QUESTA CACATA
          route: routes.MATERIALI_OPERATIVI,
          label: labels.MATERIALI_OPERATIVI,
          path: './campaigns/CampaignDetail',
        },
        {
          route: routes.MATERIALI_WEBINAR,
          label: labels.WEBINAR,
          path: './campaigns/Campaigns',
        },
      ],
      hideNexi: true,
    },
    {
      label: labels.SCEGLI_E_PERSONALIZZA,
      icon: ShoppingCartOutlined,
      open: false,
      children: [
        {
          route: routes.SCEGLI_E_PERSONALIZZA_GADGET,
          label: labels.GADGET,
          path: './gadgets/Gadgets',
        },
        {
          route: routes.SCEGLI_E_PERSONALIZZA_MERCHANDISING,
          label: labels.MERCHANDISING,
          path: './gadgets/Gadgets',
        },
        {
          route: routes.SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL,
          label: labels.PROMOTIONAL_MATERIAL,
          path: './gadgets/Gadgets',
        },
      ],
      isNexi: true,
    },
    // {
    //   route: routes.SUPPORTO_EDITORIALE,
    //   label: labels.SUPPORTO_EDITORIALE,
    //   path: './news/EditorialSupport',
    //   icon: LibraryBooksOutlined,
    // },
    {
      route: routes.DOCUMENTI,
      label: labels.DOCUMENTI,
      path: './Documents',
      icon: FolderOutlined,
      onClick: () => {
        documentStore.setLastDirectoryId('00000000-0000-0000-0000-000000000000');
      },
    },
    {
      route: routes.EVENTI,
      label: labels.EVENTI,
      path: './campaigns/Campaigns',
      icon: EventOutlined,
      hideReferral: true,
      hideNexi: true,
    },
    {
      route: routes.GESTIONE_UTENTI,
      label: labels.GESTIONE_UTENTI,
      path: './Users',
      icon: PeopleAltOutlined,
      isCustomerAdmin: true,
      hideNexi: true,
    },
    /* DEV MENU ------------------------------------------- */
    {
      route: routes.NEWS,
      label: labels.NEWS,
      path: './news/News',
      icon: Build,
      development: !isDevelopment(),
    },
    {
      route: routes.ORDINI,
      label: labels.ORDINI,
      path: './orders/Orders',
      icon: Build,
      development: !isDevelopment(),
    },
    {
      route: routes.FAQ,
      label: labels.FAQ,
      path: './Faq',
      icon: Build,
      development: !isDevelopment(),
    },
    /* NOT IN MENU ---------------------------------------- */
    {
      route: routes.NEWS_DETTAGLIO,
      title: labels.NEWS,
      path: './news/NewsDetail',
    },
    {
      route: routes.ORDINI_DETTAGLIO,
      title: labels.ORDINE,
      path: './orders/OrderDetail',
    },
    {
      route: routes.PROFILO,
      title: labels.PROFILO,
      path: './Profile',
    },
    {
      route: routes.MATERIALI_GOTOMARKET_CATEGORIA,
      title: labels.GOTO_MARKET,
      path: './campaigns/Campaigns',
    },
    {
      route: routes.MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA,
      title: labels.CAMPAGNA,
      path: './campaigns/CampaignDetail',
    },
    {
      route: routes.MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA_MATERIALE,
      title: labels.MATERIALE,
      path: './campaigns/MaterialCustomize',
    },
    {
      route: routes.MATERIALI_FORMAZIONERETE_CATEGORIA,
      title: labels.FORMAZIONE_RETE,
      path: './campaigns/Campaigns',
    },
    {
      route: routes.MATERIALI_FORMAZIONERETE_CATEGORIA_CAMPAGNA,
      title: labels.CAMPAGNA,
      path: './campaigns/CampaignDetail',
    },
    {
      route: routes.MATERIALI_FORMAZIONERETE_CATEGORIA_CAMPAGNA_MATERIALE,
      title: labels.MATERIALE,
      path: './campaigns/MaterialCustomize',
    },
    {
      route: routes.MATERIALI_WEBINAR_CATEGORIA_CAMPAGNA,
      title: labels.CAMPAGNA,
      path: './campaigns/CampaignDetail',
    },
    {
      route: routes.MATERIALI_WEBINAR_CATEGORIA_CAMPAGNA_MATERIALE,
      title: labels.MATERIALE,
      path: './campaigns/MaterialCustomize',
    },
    {
      route: routes.EVENTI_CATEGORIA_CAMPAGNA,
      title: labels.CAMPAGNA,
      path: './campaigns/CampaignDetail',
    },
    {
      route: routes.EVENTI_CATEGORIA_CAMPAGNA_MATERIALE,
      title: labels.MATERIALE,
      path: './campaigns/MaterialCustomize',
    },
    {
      route: routes.SUPPORTO_EDITORIALE_DETTAGLIO,
      title: labels.SUPPORTO_EDITORIALE,
      path: './news/NewsDetail',
    },
    {
      route: routes.CERCA,
      title: labels.CERCA,
      path: './Search',
    },
    {
      route: routes.DOCUMENTI_DETTAGLIO_CARTELLA,
      title: labels.DOCUMENTI,
      path: './Documents',
    },
    {
      route: routes.DOCUMENTI_DETTAGLIO_DOCUMENTO,
      title: labels.DOCUMENTI,
      path: './Documents',
    },
    {
      route: routes.SCEGLI_E_PERSONALIZZA_GADGET_MATERIALE,
      title: labels.GADGET,
      path: './gadgets/GadgetDetail',
    },
    {
      route: routes.SCEGLI_E_PERSONALIZZA_GADGET_MATERIALE_CUSTOMIZE,
      title: labels.GADGET,
      path: './campaigns/MaterialCustomize',
    },
    {
      route: routes.SCEGLI_E_PERSONALIZZA_MERCHANDISING_MATERIALE,
      title: labels.MERCHANDISING,
      path: './gadgets/GadgetDetail',
    },
    {
      route: routes.SCEGLI_E_PERSONALIZZA_MERCHANDISING_MATERIALE_CUSTOMIZE,
      title: labels.MERCHANDISING,
      path: './campaigns/MaterialCustomize',
    },
    {
      route: routes.SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL_MATERIALE,
      title: labels.PROMOTIONAL_MATERIAL,
      path: './gadgets/GadgetDetail',
    },
    {
      route: routes.SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL_MATERIALE_CUSTOMIZE,
      title: labels.PROMOTIONAL_MATERIAL,
      path: './campaigns/MaterialCustomize',
    },
  ];

  @computed get menu() {
    return this.pages.filter((p) => p.icon || p.customIcon);
  }

  checkElem = (elem, path) =>
    path === elem.route ||
    (path === routes.ROOT && elem.route === routes.HOMEPAGE) ||
    getRoute(path, 1) === elem.route ||
    getRoute(path, 2) === elem.route;

  @action setActive(path) {
    this.pages = this.pages.map((elem) => {
      elem.active = this.checkElem(elem, path);
      if (elem.children) {
        elem.children.map((child) => {
          child.active = this.checkElem(child, path);
          elem.open = child.active ? true : elem.open;
          elem.active = child.active ? true : elem.active;
          return child;
        });
      }
      return elem;
    });
  }

  @action toggleActive(index, childIndex = null) {
    this.pages = this.pages.map((elem, i) => {
      elem.active = i === index;
      if (elem.children) {
        elem.children.map((child, j) => {
          child.active = j === childIndex;
          elem.open = child.active ? true : elem.open;
          elem.active = child.active ? true : elem.active;
          return child;
        });
      }
      return elem;
    });
    if (this.isToggled) {
      this.closeSubMenus();
    }
  }

  @action toggleOpen(index) {
    this.pages = this.pages.map((elem, i) => {
      if (i === index) {
        elem.open = !elem.open;
      }
      return elem;
    });
  }

  @action closeSubMenus() {
    this.pages = this.pages.map((elem) => {
      elem.open = false;
      return elem;
    });
  }

  /* SIDENAV TOGGLING */

  @observable isToggled = false;

  @action toggle() {
    this.closeSubMenus();
    this.isToggled = !this.isToggled;
  }

  /* DATA */
  @action setData(label, data) {
    this.pages = this.pages.map((p) => {
      if (p.label === label.toLowerCase()) {
        p.data = { ...data };
      }
      return p;
    });
  }

  /* DOCUMENT TITLE */

  @observable documentTitle = '';

  @action setDocumentTitle(documentTitle) {
    this.documentTitle = documentTitle;
  }

  @action cleanDocumentTitle() {
    this.documentTitle = '';
  }
}
