import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { observer } from 'mobx-react';
import 'react-day-picker/lib/style.css';
import { currentDate, userStore, translationStore } from '../../../commons';

// http://react-day-picker.js.org

const WEEKDAYS_SHORT = {
  ITA: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  ENG: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
};

const WEEKDAYS_LONG = {
  ITA: [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
  ],
  ENG: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
};

const MONTHS = {
  ITA: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
  ENG: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
};

const DatePicker = observer(({ onChange, value, minDays, disabled }) => {
  const before = currentDate(minDays + 1)._d;
  return (
    <DayPickerInput
      value={value || before}
      onDayChange={day => onChange(day)}
      /* FORMATS */
      formatDate={formatDate}
      parseDate={parseDate}
      format="DD/MM/YYYY"

      inputProps={{ disabled }}
      dayPickerProps={{
        disabledDays: [
          { daysOfWeek: [0, 7] },
          { before },
        ],
        fromMonth: new Date(),
        firstDayOfWeek: 1,

        /* LOCALIZATION */

        months: translationStore.currentLang.iso3 ? MONTHS[translationStore.currentLang.iso3.toUpperCase()] : MONTHS.ITA,
        weekdaysShort: userStore.isNexi ? WEEKDAYS_SHORT.ENG : WEEKDAYS_SHORT.ITA,
        weekdaysShort: translationStore.currentLang.iso3 ? WEEKDAYS_SHORT[translationStore.currentLang.iso3.toUpperCase()] : WEEKDAYS_SHORT.ITA,
        weekdaysLong: translationStore.currentLang.iso3 ? WEEKDAYS_LONG[translationStore.currentLang.iso3.toUpperCase()] : WEEKDAYS_SHORT.ITA,
        placeholder: 'DD/MM/YYYY',
      }}
    />
  );
});

export default DatePicker;
