import React from 'react';
import Img from 'react-image';

const Image = ({ title, className, onClick, src, loader, unloader }) => (
  <Img
    src={src}
    title={title || ''}
    className={className}
    onClick={onClick}
    loader={loader}
    unloader={unloader}
  />
);

export default Image;
