import React from 'react';
import Chckbx from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../../../commons';

const styles = {
  root: {
    color: colors.MAIN,
    opacity: 1,
    borderWidth: '2px',
    padding: 0,
    marginRight: '16px',
    '&$disabled': {
      color: colors.MAIN_LIGHT_40,
      opacity: 1,
    },
    '&$checked': {
      color: colors.SECONDARY,
    },
  },
  checked: {},
  disabled: {},
};

const Checkbox = props => (
  <Chckbx
    onChange={props.onChange}
    checked={props.checked || false}
    disabled={props.disabled}
    classes={{
      root: props.classes.root,
      disabled: props.classes.disabled,
      checked: props.classes.checked,
    }}
    disableRipple
  />
);

export default withStyles(styles)(Checkbox);
