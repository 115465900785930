import { action, observable, computed, toJS } from 'mobx';

import { orderStore, checkValidQuantity, checkValidDate, formatDateForBE, customizationTypes } from '..';

import card1 from '../../assets/images/customize/cards/card1.png';
import card2 from '../../assets/images/customize/cards/card2.png';
import card3 from '../../assets/images/customize/cards/card3.png';

export default class CustomizeStore {
  baseFields = {
    disabledAll: false,
    noCustomize: true,
    advanced: false,
    printRequested: false,
    quantity: 1,
    shippingSites: [],
    addressNotes: '',
    expectedDeliveryDate: '',
    canDeliverOnSaturday: false,
    notes: '',
    attachments: [],
    files: [],
  };
  @observable fields = this.baseFields;
  baseIsValid = {
    quantity: true,
    expectedDeliveryDate: true,
    shippingSite: true,
  };
  @observable isValid = this.baseIsValid;

  @computed get isPrintable() {
    return !this.fields.printRequested && !this.fields.advanced;
  }

  @computed get isFormValid() {
    return this.isValid.quantity
      && this.isValid.expectedDeliveryDate
      && this.isValid.shippingSite;
  }

  @action checkCustomization(customizationTypeLabel) {
    const { advanced, noCustomize } = this.fields;
    return (!noCustomize
      && ((customizationTypeLabel === customizationTypes.MANUAL && advanced)
        || (customizationTypeLabel === customizationTypes.AUTOMATED && !advanced)));
  }

  @action setDisabledAll(disabledAll) {
    this.fields.disabledAll = disabledAll;
  }

  @action setNoCustomize(noCustomize) {
    this.fields.noCustomize = noCustomize;
  }

  @action setAdvanced(advanced) {
    this.fields.advanced = advanced;
  }

  @action setPrintRequested(printRequested) {
    this.fields.printRequested = printRequested;
  }

  @action togglePrintRequested() {
    this.fields.printRequested = !this.fields.printRequested;
  }

  @action setQuantity(quantity, min = null, max = null) {
    const isValid = checkValidQuantity(quantity, min, max);
    this.isValid.quantity = isValid;
    this.fields.quantity = quantity;
  }

  @action toggleAddShippingSite(value = !this.isValid.shippingSite) {
    this.isValid.shippingSite = value;
  }

  @action addShippingSite(shippingSite) {
    this.baseFields.shippingSites.push(shippingSite);
    this.fields.shippingSites.push(shippingSite);
  }

  @action setShippingSites(shippingSites) {
    this.baseFields.shippingSites = shippingSites;
    this.fields.shippingSites = shippingSites;
  }

  @action changeShippingSite(siteId) {
    this.fields.shippingSites = toJS(this.fields.shippingSites)
      .sort((x, y) => (x.id === siteId ? -1 : (y.id === siteId ? 1 : 0)))
      .map((s) => {
        s.preferred = (s.id === siteId);
        return s;
      });
  }

  @action setAddressExtraInfo(addressNotes) {
    this.fields.addressNotes = addressNotes;
  }

  @action setExpectedDeliveryDate(expectedDeliveryDate) {
    const isValid = checkValidDate(expectedDeliveryDate);
    this.isValid.expectedDeliveryDate = isValid;
    this.fields.expectedDeliveryDate = expectedDeliveryDate;
  }

  @action toggleCanDeliveryOnSaturday() {
    this.fields.canDeliverOnSaturday = !this.fields.canDeliverOnSaturday;
  }

  @action setNotes(notes) {
    this.fields.notes = notes;
  }

  @action setFiles(files) {
    this.fields.files = files;
    this.fields.attachments = [];
  }

  @action addAttachments(id) {
    const { attachments } = this.fields;
    attachments.push({ attachmentId: id });
    this.fields.attachments = attachments;
  }

  @action undoEdit() {
    this.fields = this.baseFields;
    this.isValid = this.baseIsValid;
  }

  /* CONFIRM CUSTOMIZATION ------------------------------------ */

  @action confirm(material) {
    const {
      advanced,
      notes,
      attachments,
      shippingSites,
      printRequested,
      addressNotes,
    } = this.fields;

    let {
      quantity,
      canDeliverOnSaturday,
      expectedDeliveryDate,
    } = this.fields;

    quantity = !printRequested && material.downloadAllowed && !advanced ? 1 : quantity;
    canDeliverOnSaturday = !printRequested
      ? false : canDeliverOnSaturday;
    expectedDeliveryDate = !printRequested
      ? null : formatDateForBE(expectedDeliveryDate);
    const shippingSite = !printRequested
      ? null : shippingSites[0];

    orderStore.push({
      notes,
      printRequested,
      quantity,
      canDeliverOnSaturday,
      expectedDeliveryDate,
      attachments,
      materialId: material.id,
      materialCustomizationType: this.fields.advanced ? 1 : 0,
      materialParameters: material.parameters
        .filter(p => this.checkCustomization(p.customizationTypeLabel)),
      ...shippingSite,
      addressNotes,
    });
  }

  /* MOCK ----------------------------------------------------- */

  @observable cards = [
    {
      id: 'card1',
      src: card1,
    },
    {
      id: 'card2',
      src: card2,
    },
    {
      id: 'card3',
      src: card3,
    },
  ];

  @computed get noCards() {
    return this.cards.length === 0;
  }

  @action setSelectedCard(index) {
    this.cards = this.cards.map((card, i) => {
      card.selected = (i === index);
      return card;
    });
  }
}
