import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { translationStore, labels } from '../../../commons';

const Select = props => (
  <UncontrolledDropdown className="select">
    <DropdownToggle
      disabled={props.disabled}
      className={props.disabled ? 'disabled' : ''}
      tag="span"
      caret
    >
      {props.selected}
    </DropdownToggle>
    {props.options.length > 1 || props.onClickAdd ? (
      <DropdownMenu flip={false}>
        {props.options.map((option) => {
          const label = props.getLabel(option);
          const value = props.getValue(option);
          return (label !== props.selected ? (
            <DropdownItem
              tag="div"
              key={value}
              onClick={() => props.onChange(value)}
            >
              {label}
            </DropdownItem>
          ) : '');
        })}
        {props.onClickEdit ? (
          <DropdownItem
            tag="div"
            key="edit"
            onClick={() => props.onClickEdit()}
          >
            {props.onClickEditLabel || translationStore.get(labels.MODIFICA_INDIRIZZI)}
            ...
          </DropdownItem>
        ) : ''}
        {props.onClickAdd ? (
          <DropdownItem
            tag="div"
            key="add"
            onClick={() => props.onClickAdd()}
          >
            {props.onClickAddLabel || translationStore.get(labels.AGGIUNGI_NUOVO)}
            ...
          </DropdownItem>
        ) : ''}
      </DropdownMenu>
    ) : ''}
  </UncontrolledDropdown>
);

export default Select;
