import React from 'react';
import ReactSVG from 'react-svg';

const Svg = ({ src, className, onClick }) => (
  <ReactSVG
    src={src}
    beforeInjection={(svg) => {
      svg.classList.add(className);
    }}
    wrapper="span"
    className="svg-wrapper"
    onClick={onClick}
  />
);

export default Svg;
