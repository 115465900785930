import React from 'react';
import { Button as Btn } from 'reactstrap';
import { Link } from 'react-router-dom';
import LdngBtn, { S, CONTRACT } from 'react-ladda';

import { Svg } from '../..';

// https://github.com/jsdir/react-ladda

const LoadingButton = props => (
  <LdngBtn
    className={`btn btn-${props.color}${props.icon ? ' icon-button' : ''}`}
    loading={props.loading}
    onClick={props.onClick}
    data-color="#707295"
    data-size={S}
    data-style={CONTRACT}
    data-spinner-size={18}
    data-spinner-color="#707295"
    data-spinner-lines={8}
    disabled={props.disabled}
  >
    {props.icon ? <props.icon /> : ''}
    {props.customIcon ? <Svg src={props.customIcon} /> : ''}
    {props.label}
  </LdngBtn>
);

const DownloadButton = props => (
  <a
    className={`btn btn-${props.color}${props.icon ? ' icon-button' : ''}`}
    href={props.to}
    disabled={props.disabled}
    download
  >
    {props.icon ? <props.icon /> : ''}
    {props.customIcon ? <Svg src={props.customIcon} /> : ''}
    {props.label}
  </a>
);

const Button = props => (props.withLoading
  ? <LoadingButton {...props} />
  : props.download
    ? <DownloadButton {...props} />
    : (
      <Btn
        className={props.className ? (props.icon ? `${props.className} icon-button` : props.className) : (props.icon ? 'icon-button' : undefined)}
        onClick={props.onClick}
        color={props.color}
        size={props.size}
        disabled={props.disabled}
        block={props.block}
        outline={props.outline}
        tag={(props.to && Link) || props.tag}
        to={props.to}
        data-toggle={props.dataToggle}
        href={props.href}
        target={props.target}
        active={props.active}
        title={props.title}
      >
        {props.icon ? <props.icon /> : ''}
        {props.customIcon ? <Svg src={props.customIcon} /> : ''}
        {props.label}
      </Btn>
    ));

export default Button;
