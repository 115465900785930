import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Redirect, Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { observer } from 'mobx-react';

import { routes, translationStore, navigationStore, isDemo } from '../commons';
import { Loading } from '../components';

/* LOADING ----------------------------------------------- */

const withLoadable = (path, full = false) => (
  Loadable({
    loader: () => import(`${path}`),
    loading: () => <Loading full={full} />,
  })
);

/* TITLE ----------------------------------------------- */

const withPageTitle = (Page, title) => {
  const PageTitle = observer((props) => {
    const detail = navigationStore.documentTitle && `: ${navigationStore.documentTitle}`;
    const tenant = isDemo() ? 'Demo' : 'Nexi Ready';
    return (
      <DocumentTitle
        title={`${tenant} | ${translationStore.get(title)}${detail}`}
      >
        <Page {...props} />
      </DocumentTitle>
    );
  });
  return PageTitle;
};

/* PAGES ----------------------------------------------- */

const Layout = withLoadable('./Layout', true);

class Routes extends Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.location.pathname !== nextProps.location.pathname);
  }

  componentDidUpdate() {
    navigationStore.cleanDocumentTitle();
  }

  renderRoute = ({ route, path, title, label, data }) => (
    <Route
      key={`${route}`}
      exact
      path={`${route}`}
      component={withPageTitle(withLoadable(path), title || label, data)}
    />
  );

  render() {
    return (
      <Switch>
        {navigationStore.pages
          .filter(p => p.hidden !== false)
          .map(page => (page.children
            ? page.children.map(child => this.renderRoute(child))
            : this.renderRoute(page)
          ))}
        <Redirect from={routes.ROOT} to={routes.HOMEPAGE} />
      </Switch>
    );
  }
}

export { Layout };
export default Routes;
