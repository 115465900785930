export const campaignIds = {
  GADGET: 'ccb754bd-c5fe-4fc5-f668-08da54525bcc',
  MATERIALI_OPERATIVI: '78037097-6187-4e20-7a0e-08d6919b4b3f',
  MERCHANDISING: 'a57afba8-80bb-4084-c7f4-08da6b0540f1',
  PROMOTIONAL_MATERIAL: '76fe1e4b-0df7-4078-4ed8-08dacef11324'
};

export const routes = {
  BACK_OFFICE: '/admin/',
  CERCA: '/homepage/cerca',
  DOCUMENTI_BASE: '/documenti',
  DOCUMENTI_DETTAGLIO_CARTELLA: '/documenti/:directoryId',
  DOCUMENTI_DETTAGLIO_DOCUMENTO: '/documenti/:directoryId/:itemId',
  DOCUMENTI: '/documenti/00000000-0000-0000-0000-000000000000',
  EVENTI_CATEGORIA_CAMPAGNA_MATERIALE: '/eventi/:categoryId/:campaignId/:materialId',
  EVENTI_CATEGORIA_CAMPAGNA: '/eventi/:categoryId/:campaignId',
  EVENTI_CATEGORIA: '/eventi/:categoryId',
  EVENTI: '/eventi',
  FAQ: '/homepage/faq',
  GESTIONE_UTENTI: '/gestione-utenti',
  HOMEPAGE: '/homepage',
  MATERIALI_FORMAZIONERETE_CATEGORIA_CAMPAGNA_MATERIALE: '/materiali/formazionerete/:categoryId/:campaignId/:materialId',
  MATERIALI_FORMAZIONERETE_CATEGORIA_CAMPAGNA: '/materiali/formazionerete/:categoryId/:campaignId',
  MATERIALI_FORMAZIONERETE_CATEGORIA: '/materiali/formazionerete/:categoryId',
  MATERIALI_FORMAZIONERETE: '/materiali/formazionerete',
  MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA_MATERIALE: '/materiali/gotomarket/:categoryId/:campaignId/:materialId',
  MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA: '/materiali/gotomarket/:categoryId/:campaignId',
  MATERIALI_GOTOMARKET_CATEGORIA: '/materiali/gotomarket/:categoryId',
  MATERIALI_GOTOMARKET: '/materiali/gotomarket',
  // TODO: LEVA QUESTA CACATA
  MATERIALI_OPERATIVI: `/materiali/materiali-operativi/${campaignIds.MATERIALI_OPERATIVI}`,
  MATERIALI_WEBINAR_CATEGORIA_CAMPAGNA_MATERIALE: '/materiali/webinar/:categoryId/:campaignId/:materialId',
  MATERIALI_WEBINAR_CATEGORIA_CAMPAGNA: '/materiali/webinar/:categoryId/:campaignId',
  MATERIALI_WEBINAR_CATEGORIA: '/materiali/webinar/:categoryId',
  MATERIALI_WEBINAR: '/materiali/webinar',
  MATERIALI: '/materiali',
  NEWS_DETTAGLIO: '/homepage/news/:newsId',
  NEWS: '/homepage/news',
  ORDINI_DETTAGLIO: '/homepage/ordini/:orderId',
  ORDINI: '/homepage/ordini',
  PROFILO: '/homepage/profilo',
  ROOT: '/',

  SCEGLI_E_PERSONALIZZA: '/scegli-e-personalizza',
  SCEGLI_E_PERSONALIZZA_GADGET_MATERIALE_CUSTOMIZE: '/scegli-e-personalizza/gadget/:campaignId/:materialId',
  SCEGLI_E_PERSONALIZZA_GADGET_MATERIALE: '/scegli-e-personalizza/gadget/:materialId',
  SCEGLI_E_PERSONALIZZA_GADGET: '/scegli-e-personalizza/gadget',

  SCEGLI_E_PERSONALIZZA_MERCHANDISING_MATERIALE_CUSTOMIZE: '/scegli-e-personalizza/merchandising/:campaignId/:materialId',
  SCEGLI_E_PERSONALIZZA_MERCHANDISING_MATERIALE: '/scegli-e-personalizza/merchandising/:materialId',
  SCEGLI_E_PERSONALIZZA_MERCHANDISING: '/scegli-e-personalizza/merchandising',

  SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL_MATERIALE_CUSTOMIZE: '/scegli-e-personalizza/promotional-material/:campaignId/:materialId',
  SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL_MATERIALE: '/scegli-e-personalizza/promotional-material/:materialId',
  SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL: '/scegli-e-personalizza/promotional-material',

  SUPPORTO_EDITORIALE_DETTAGLIO: '/supporto-editoriale/:newsId',
  SUPPORTO_EDITORIALE: '/supporto-editoriale',
};

export const apiRoutes = {
  ADDRESS_DETAIL: '/addressSearch/{addressId}',
  ADDRESSES: '/addressSearch',
  CAMPAIGN_DETAIL: '/campaigns/items/{campaignId}',
  CAMPAIGN_INSTRUCTIONS: '/campaigns/items/{campaignId}/instructions',
  CAMPAIGNS: '/campaigns/items',
  CATEGORIES: '/categories/byEntity/{entityName}',
  COUNTRY: '/countries/items',
  CUSTOMER: '/customers/current',
  DOCUMENTS_TREE: '/documents/tree/',
  FAQ: '/frequentlyAskedQuestions/items',
  FILE_PREVIEW: '/files/items/{fileId}/preview',
  FILE_UPLOAD: '/files/items',
  FILE_ZIP: '/files/batch',
  FILE: '/files/items/{fileId}',
  HELP_DETAIL: '/helpRequests/items/{helpRequestId}',
  HELP_REPLY: '/helpRequests/items/{helpRequestId}/replies',
  HELP: '/helpRequests/items',
  LANGUAGES: '/languages/items',
  MATERIAL_CUSTOMIZE_PDF: '/admin/assets/pdf-viewer/web/viewer.html?file=/admin/api/portal/materials/items/{materialId}/customizationPreview{qs}#readonly=true&zoom=page-fit',
  MATERIAL_CUSTOMIZE: '/admin/api/portal/materials/items/{materialId}/customizationPreview{qs}',
  MATERIAL_DETAIL: '/materials/items/00000000-0000-0000-0000-000000000000/files/{materialId}',
  NEWS_DETAIL: '/news/items/{newsId}',
  NEWS: '/news/items',
  ORDER_CANCEL: '/orders/items/{orderId}/cancel',
  ORDER_DETAIL: '/orders/items/{orderId}',
  ORDER_DOWNLOAD: '/orders/items/{orderId}/download',
  ORDERS_STATUS: '/orders/status',
  ORDERS: '/orders/items',
  PDF: '/admin/assets/pdf-viewer/web/viewer.html?file=/admin/api/portal/files/items/{fileId}#zoom=page-fit',
  SEARCH: '/azure/suggest',
  TRANSLATIONS: '/translations/map',
  USER_CONSENT: '/users/current/consent',
  USER_GET_POST: '/customers/current/users',
  USER_GROUPS: '/users/groups',
  USER_NOTIFICATIONS_MARKASREAD: '/users/current/notifications/batch',
  USER_NOTIFICATIONS: '/users/current/notifications/items',
  USER_LANGUAGE: '/users/current/language',
  USER_PUT: '/customers/current/users/{usersId}',
  USER: '/users/current',
};

export const labels = {
  ABI: 'abi',
  ACCOUNT_RIFERIMENTO: 'account_riferimento',
  AGGIUNGI_NUOVO: 'aggiungi_nuovo',
  AGGIUNGI_UTENTE: 'aggiungi_utente',
  AGGIUNGI: 'aggiungi',
  AL: 'al',
  ALLEGATI: 'allegati',
  ALTRO_INDIRIZZO: 'altro_indirizzo',
  ANNULLA: 'annulla',
  ARTICOLI_VIDEO_DISPONIBILI: 'articoli_video_disponibili',
  ATTACHMENTS: 'attachments',
  ATTENZIONE: 'attenzione',
  AVANTI: 'avanti',
  AVANZATA_DISCLAIMER: 'avanzata_disclaimer',
  AVANZATA: 'avanzata',
  BANCA_CENTRALE: 'banca_centrale',
  CAMBIA: 'cambia',
  CAMPAGNA: 'campagna',
  CAMPAGNE_ATTIVE: 'campagne_attive',
  CAMPAGNE: 'campagne',
  CARD_DESIGN_ASK: 'card_design_ask',
  CARD_DESIGN_NO: 'card_design_no',
  CARD_DESIGN_SCEGLI: 'card_design_scegli',
  CARD_DESIGN: 'card_design',
  CELLULARE: 'cellulare',
  CERCA: 'cerca',
  CHIUDI_ANTEPRIMA: 'chiudi_anteprima',
  CHIUDI: 'chiudi',
  CLICCA_QUI: 'clicca_qui',
  COGNOME: 'cognome',
  COMPILA_NOTE: 'compila_note',
  COMUNICAZIONI: 'comunicazioni',
  CONFERMA_INDIRIZZO_DISCLAIMER: 'conferma_indirizzo_disclaimer',
  CONFERMA_INDIRIZZO_NO: 'conferma_indirizzo_no',
  CONFERMA_INDIRIZZO_SI: 'conferma_indirizzo_si',
  CONFERMA_QUANTITA_DISCLAIMER: 'conferma_quantita_disclaimer',
  CONFERMA: 'conferma',
  CONSEGNA_SABATO: 'consegna_sabato',
  CONSEGNA_STIMATA: 'consegna_stimata',
  CONSULTA: 'consulta',
  CONTRATTI_CARTE: 'contratti_carte',
  CONTRATTI_ESERCENTI: 'contratti_esercenti',
  CONVERSAZIONE: 'conversazione',
  COOKIES_DISCLAIMER: 'cookies_disclaimer',
  COOKIES_MORE_INFO: 'cookies_more_info',
  DAL: 'dal',
  DATA_CONSEGNA_DESIDERATA: 'data_consegna_desiderata',
  DATA_INVALIDA: 'data_invalida',
  DATI_ANAGRAFICI: 'dati_anagrafici',
  DATI: 'dati',
  DEPTH: 'material_descriptor_depth',
  DESCRIVI_PROBLEMA: 'descrivi_problema',
  DETAIL: 'detail',
  DI: 'di',
  DOCUMENTI: 'documenti',
  DOWNLOAD_DISCLAIMER: 'download_disclaimer',
  DOWNLOAD_DISPONIBILE: 'download_disponibile',
  DOWNLOAD: 'download',
  EDITOR: 'editor',
  EDITORIALE: 'editoriale',
  ELIMINA_UTENTE_DISCLAIMER: 'elimina_utente_disclaimer',
  ELIMINA: 'elimina',
  EMAIL: 'email',
  ERRORE: 'errore',
  ESPLORA: 'esplora',
  EVENTI: 'eventi',
  FAQ_GOTO: 'faq_goto',
  FAQ: 'faq',
  FILIALE_RIFERIMENTO: 'filiale_riferimento',
  FILIALE: 'filiale',
  FILTRA: 'filtra',
  FORMAZIONE_RETE: 'formazione_rete',
  GADGET: 'gadget',
  GESTIONE_UTENTI: 'gestione_utenti',
  GOTO_BACK_OFFICE: 'goto_back_office',
  GOTO_MARKET: 'goto_market',
  HEIGHT: 'material_descriptor_height',
  HELP_SUCCESS_MSG: 'help_success_msg',
  HELP: 'help',
  HOMEPAGE: 'homepage',
  IN_PRIMO_PIANO: 'in_primo_piano',
  INDIETRO: 'indietro',
  INDIRIZZO_CONSEGNA_ORDINE: 'indirizzo_consegna_ordine',
  INDIRIZZO_CONSEGNA: 'indirizzo_consegna',
  INDIRIZZO_INFO_AGGIUNTIVE: 'indirizzo_info_agggiuntive',
  INVIA: 'invia',
  ISTRUZIONI: 'istruzioni',
  LEGGI: 'leggi',
  LOGHI_PROFILO: 'loghi_profilo',
  LOGIN: 'login',
  LOGO_DEFAULT: 'logo_default',
  LOGO_DISCLAIMER: 'logo_disclaimer',
  LOGO: 'logo',
  LOGOUT: 'logout',
  MAILING_LIST: 'mailing_list',
  MATERIALE: 'materiale',
  MATERIALI_DISCLAIMER: 'materiali_disclaimer',
  MATERIALI_EVENTI: 'materiali_eventi',
  MATERIALI_FORMAZIONE_RETE: 'materiali_formazione_rete',
  MATERIALI_OPERATIVI: 'materiali_operativi',
  MATERIALI_PRODOTTO_CAMPAGNE: 'materiali_prodotto_campagne',
  MATERIALI_WEBINAR: 'materiali_webinar',
  MATERIALI: 'materiali',
  MERCHANDISING: 'merchandising',
  MODIFICA_DATI: 'modifica_dati',
  MODIFICA_INDIRIZZI: 'modifica_indirizzi',
  MODIFICA: 'modifica',
  MOTIVAZIONE: 'motivazione',
  NESSUNA_DISCLAIMER: 'nessuna_disclaimer',
  NESSUNA_SCADENZA: 'nessuna_scadenza',
  NESSUNA: 'nessuna',
  NEWS_TUTTE: 'news_tutte',
  NEWS: 'news',
  NO_DATA_RICERCA: 'no_data_ricerca',
  NO_DATA: 'no_data',
  NO_NOTIFICHE: 'no_notifiche',
  NO_PERSONALIZZAZIONE: 'no_personalizzazione',
  NO: 'no',
  NOME_UTENTE: 'nome_utente',
  NOME: 'nome',
  NOTE_E_ALLEGATI: 'note_e_allegati',
  NOTE_PLACEHOLDER: 'note_placeholder',
  NOTE: 'note',
  NOTIFICHE: 'notifiche',
  NUMERO_INVALIDO: 'numero_invalido',
  NUMERO_ORDINE: 'numero_ordine',
  NUOVO_INDIRIZZO: 'nuovo_indirizzo',
  NUOVO_ORDINE: 'nuovo_ordine',
  ORDINA_DINUOVO: 'ordina_dinuovo',
  ORDINATO_IL: 'ordinato_il',
  ORDINE_ANNULLA_DISCLAIMER: 'ordine_annulla_disclaimer',
  ORDINE_ANNULLA: 'ordine_annulla',
  ORDINE_DETTAGLI: 'ordine_dettagli',
  ORDINE_IN_ELABORAZIONE: 'ordine_in_elaborazione',
  ORDINE_INOLTRATO: 'ordine_inoltrato',
  ORDINE_MODIFICA: 'ordine_modifica',
  ORDINE_PRONTO: 'ordine_pronto',
  ORDINE: 'ordine',
  ORDINI_TUTTI: 'ordini_tutti',
  ORDINI: 'ordini',
  OTHER_CHARACTERISTICS: 'other_characteristics',
  PAGINA_PUBBLICITARIA: 'pagina_pubblicitaria',
  PARAMETRO_PREZZO: 'parametro_prezzo',
  PEC: 'pec',
  PER: 'per',
  PERSONALIZZA: 'personalizza',
  PRESONALIZZAZIONE_LOGO: 'personalizzazione_logo',
  PROCEED: 'proceed',
  PROFILO: 'profilo',
  PROMOTIONAL_MATERIAL: 'promotional_material',
  QUANTITA_INVALIDA: 'quantita_invalida',
  QUANTITA_MASSIMA_MOLTI: 'quantita_massima_molti',
  QUANTITA_MASSIMA_UNO: 'quantita_massima_uno',
  QUANTITA: 'quantita',
  REFERENTE: 'referente',
  RESETTA: 'resetta',
  RICHIEDI_ASSISTENZA: 'richiedi_assistenza',
  RICHIEDI_STAMPA_DISCLAIMER: 'richiedi_stampa_disclaimer',
  RICHIEDI_STAMPA: 'richiedi_stampa',
  RIEPILOGO: 'riepilogo',
  RIPRODUCI: 'riproduci',
  SALVA: 'salva',
  SCARICA: 'scarica',
  SCEGLI_E_PERSONALIZZA: 'scegli_e_personalizza',
  SCEGLI: 'scegli',
  SCHEDE_PRODOTTO: 'schede_prodotto',
  SEDE_CENTRALE: 'sede_centrale',
  SELEZIONA: 'seleziona',
  SERVE_AIUTO: 'serve_aiuto',
  SFOGLIA: 'sfoglia',
  SI: 'si',
  SPEDITO: 'spedito',
  STAMPA_AUTONOMA: 'stampa_autonoma',
  STANDARD_DISCLAIMER: 'standard_disclaimer',
  STANDARD: 'standard',
  STATO: 'stato',
  STORICO_ORDINI: 'storico_ordini',
  SUPPLIER: 'supplier',
  SUPPORTO_EDITORIALE: 'supporto_editoriale',
  SUPPORTO: 'supporto',
  TELEFONO: 'telefono',
  TIPO_PERSONALIZZAZIONE: 'tipo_personalizzazione',
  TIPO_PROBLEMA: 'tipo_problema',
  TIPO: 'tipo',
  TORNA_HOME: 'torna_home',
  ULTIME_NEWS: 'ultime_news',
  ULTIMI_ORDINI: 'ultimi_ordini',
  VALIDITA: 'validita',
  VEDI_ANTEPRIMA: 'vedi_anteprima',
  VEDI_TUTTE: 'vedi_tutte',
  VEDI_TUTTI: 'vedi_tutti',
  VIDEO_ALTRI: 'video_altri',
  VIDEO_EVIDENZA: 'video_evidenza',
  VIDEO: 'video',
  VISUALIZZA_PIU: 'visualizza_piu',
  VISUALIZZA_TUTTI: 'visualizza_tutti',
  VISUALIZZA: 'visualizza',
  WEBINAR: 'webinar',
  WIDTH: 'material_descriptor_width',
  WIP: 'wip',
};

export const newsCategories = {
  ALERT: 'news_category_alert',
  FEATURED: 'news_category_featured',
  HIGHLIGHT: 'news_category_highlight',
  NEWS: 'news_category_news',
  SUPPORTO_EDITORIALE: 'news_category_publishing',
};

export const campaignCategories = {
  CARDS: 'campaign_category_1',
  MERCHANT: 'campaign_category_2',
  MOBILE: 'campaign_category_3',
  INIZIATIVE: 'campaign_category_4',
  BANKING_SOLUTIONS: 'campaign_category_11',
  PUBLIC_ADMIN: 'campaign_category_12',
  SUSTAINABILITY: 'campaign_category_13',
  SECURITY: 'campaign_category_17',

  // NEXI
  GADGET: 'campaign_category_14',
  MERCH: 'campaign_category_15',
  PROMO: 'campaign_category_16',

  // FORMAZIONE RETE
  CARDS_DIGITAL: 'campaign_category_5',
  MERCHANT_SOLUTIONS: 'campaign_category_6',

  // TODO: DEMO
  DEMO1: 'campaign_category_demo1',
  DEMO2: 'campaign_category_demo2',
};

export const campaignCategoriesFormazioneRete = [
  campaignCategories.CARDS_DIGITAL,
  campaignCategories.MERCHANT_SOLUTIONS,
];

export const campaignCategoriesWebinar = [
  'campaign_category_7'
];

export const campaignCategoriesEventi = [
  'campaign_category_8'
];

export const campaignCategoriesNexi = [
  campaignCategories.GADGET,
  campaignCategories.MERCH,
  campaignCategories.PROMO
];

export const materialCategories = {
  ATM: 'material_category_atm',
  BANNER: 'material_category_banner',
  CARTOLINA: 'material_category_cartolina',
  COUPON: 'material_category_coupon',
  FLYER: 'material_category_flyer',
  LOCANDINA: 'material_category_locandina',
  SCHEDA_PRODOTTO: 'material_category_scheda_prodotto',
  VETROFANIA: 'material_category_vetrofania',
  VIDEO: 'material_category_video',
  VOLANTINO: 'material_category_leaflet',
};

export const customizationTypes = {
  AUTOMATED: 'material_customization_type_automated',
  MANUAL: 'material_customization_type_manual',
};

export const materialParamCategories = {
  ARTWORK: 'material_parameter_category_artwork',
  CHECKBOX: 'material_parameter_category_checkbox',
  MONEY: 'material_parameter_category_money',
};

export const materialParamCategoryType = {
  LOGO_NEGATIVE: 'customer_artwork_category_negative_logo',
  LOGO_POSITIVE: 'customer_artwork_category_positive_logo',
};

export const materialParamCategoryIds = {
  LOGO_POSITIVE: '00000000-0000-0000-0000-000000000001',
  LOGO_NEGATIVE: '00000000-0000-0000-0000-000000000002',
};

export const orderStatus = {
  ANNULLED: 'order_status_canceled',
  APPROVED: 'order_status_approved',
  DOWNLOADABLE: 'order_status_downloadable',
  ERROR: 'order_status_error',
  PRINTING: 'order_status_printing',
  PROCESSING: 'order_status_processing',
  RECEIVED: 'order_status_received',
  SENT: 'order_status_sent',
};

export const storage = {
  LANGUAGE: 'language',
};

export const errors = {
  ERR_400: 'err_400',
  ERR_404: 'err_404',
  ERR_500: 'err_500',
};

/* BACK END CONSTANTS */

export const defaultLanguages = {
  ITA: {
    "description": "Italiano",
    "flagCss": "flag-icon flag-icon-it",
    "hasTranslations": true,
    "id": "00000000-0000-0000-0000-000000000001",
    "iso2": "it",
    "iso3": "ita",
    "customProperties": {}
  },
  ENG: {
    "description": "English",
    "flagCss": "flag-icon flag-icon-gb",
    "hasTranslations": true,
    "id": "00000000-0000-0000-0000-000000000002",
    "iso2": "en",
    "iso3": "eng",
    "customProperties": {}
  },
};

export const fileTypes = {
  DIRECTORY_TYPE: 'application/octet-stream',
  DIRECTORY: 'Directory',
  DOC: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  FILE: 'File',
  HTML: 'text/html',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PNG: 'image/png',
  PPT: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  SVG: 'image/svg+xml',
  ZIP: 'application/x-zip-compressed',
};

export const userRoles = {
  CONTACT: 'role_contact',
  ALTRI: '',
};

/* OTHER CONSTANTS */

export const colors = {
  DANGER: '#EB5757',
  LIGHT_BG: '#F8F9FD',
  MAIN_DARK_40: '#44455B',
  MAIN_LIGHT_20: '#ABACC0',
  MAIN_LIGHT_40: '#E2E3E9',
  MAIN: '#707295',
  PRIMARY_LIGHT_40: '#B7B9EC',
  PRIMARY_SAT_40: '#020AD5',
  PRIMARY: '#2832aa',
  SECONDARY_LIGHT_40: '#C1F4FF',
  SECONDARY: '#00CAF4',
  SUCCESS: '#6FCF97',
  WARNING: '#FFD041',
};

export const sourceType = {
  CAMPAIGN: 'Campaign',
  DOCUMENT: 'Document',
  MATERIAL: 'Material',
  NEWS: 'News',
  NONE: 0,
  ORDER: 'Order',
};

export const notificationTypes = {
  DOCUMENT: 'Document',
  NONE: 'None',
  ORDER: 'Order',
};

export const inputTypes = {
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  FILE: 'file',
  MONEY: 'money',
  MULTISELECT: 'multiselect',
  NUMBER: 'number',
  RADIO: 'radio',
  SELECT: 'select',
  SWITCH: 'switch',
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

const translationPrefix = (process.env.REACT_APP_TRANSLATION_PREFIX || '').toString();

export const system = {
  PREFIX: translationPrefix === '0' ? '' : translationPrefix,
};
