import $ from 'jquery';

import { translationStore } from '..';

export function isDevelopment() {
  return (
    !process.env.NODE_ENV
    || process.env.NODE_ENV === 'development'
  );
}

export function setTooltip(tag) {
  $(() => {
    $(tag).each((i, elem) => {
      if (elem.offsetWidth < elem.scrollWidth
        || elem.parentElement.offsetWidth < elem.parentElement.scrollWidth) {
        const title = elem.lastChild.data;
        $(elem).attr('title', title);
      }
    });
  });
}

export function buildOrderName(materialCategoryLabel, campaignTitle) {
  return `${translationStore.get(materialCategoryLabel)} ${campaignTitle}`;
}

export function isDemo() {
  return window.appConfig.tenant.id === 'demo';
}
