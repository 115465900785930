import { action, observable, computed, toJS } from 'mobx';

import { api, apiRoutes, sequentialGet, newsCategories, queryBuilder, getVideoPreview } from '..';

export default class NewsStore {
  @observable items = [];
  @observable isFetched = false;
  @observable featured = [];
  defaultFilters = {
    search: '',
    article: false,
    video: false,
    categories: [
      newsCategories.SUPPORTO_EDITORIALE,
      newsCategories.ALERT,
      newsCategories.FEATURED,
      newsCategories.HIGHLIGHT,
      newsCategories.NEWS,
    ],
  };
  @observable filters = this.defaultFilters;
  defaultSkip = 0;
  defaultTake = 20;

  @observable hasShowMore = false;
  @observable loading = false;
  countSkip = this.defaultTake;

  @computed get noNews() {
    return this.isFetched
      && this.items.peek().length === 0
      && this.featured.peek().length === 0;
  }

  @action fetchNews(home = false, editorials = false, newsPreviewId = '', showMore = false) {
    if (!showMore) {
      this.isFetched = false;
      this.items = [];
      this.featured = [];
    } else {
      this.loading = true;
    }

    const query = queryBuilder(
      showMore ? this.countSkip : this.defaultSkip,
      this.defaultTake,
      this.filters,
      home,
      editorials
    );
    const request = home ? api.get : sequentialGet;

    request(`${apiRoutes.NEWS}?${query}`)
      .then(async (response) => {
        if (newsPreviewId) {
          const newsPreview = await this.fetchNewsDetail(newsPreviewId);
          response.data.data = [toJS(newsPreview), ...response.data.data];
          this.cleanUpDetail();
        }
        let items = response ? this.sortItems(response.data.data) : [];
        let featured = items.filter(this.isFeatured);

        if (items.length > 0) {
          if (!featured.length) {
            const highlights = items.filter(this.isHighlight);
            if (highlights.length) {
              featured = [highlights[0]];
            } else {
              featured = [items.filter(this.isNews)[0]];
            }
          } else {
            featured = [featured[0]];
          }
          if (home) {
            items = items.filter(i => i.id !== featured[0].id);
          }

          /* VIDEO PREVIEW */
          items = await getVideoPreview(items, 'coverVideoUrl');
          featured = await getVideoPreview(featured, 'coverVideoUrl');
        }

        this.hasShowMore = response.data.filteredCount > (this.countSkip + this.defaultTake);

        if (!showMore) {
          this.items = items;
          this.featured = featured;
          this.isFetched = true;
        } else {
          this.items = this.items.concat(items);
          this.countSkip += this.defaultTake;
          this.loading = false;
        }
      });
  }

  @action showMore() {
    this.fetchNews(false, false, '', true);
  }

  /* FILTERS */

  @action filterBySearch(search, editorials = false) {
    this.filters.search = search;
    this.fetchNews(false, editorials);
  }

  @action filterByArticle(editorials = false) {
    this.filters.article = !this.filters.article;
    if (this.filters.video && this.filters.article) {
      this.filters.video = !this.filters.video;
    }
    this.fetchNews(false, editorials);
  }

  @action filterByVideo(editorials = false) {
    this.filters.video = !this.filters.video;
    if (this.filters.article && this.filters.video) {
      this.filters.article = !this.filters.article;
    }
    this.fetchNews(false, editorials);
  }

  /* NEWS DETAIL */
  @observable selectedNews = {};

  @computed get detailIsFetched() {
    return Object.keys(this.selectedNews).length !== 0
      && this.selectedNews.constructor === Object;
  }

  @action fetchNewsDetail(id) {
    const query = apiRoutes.NEWS_DETAIL
      .replace('{newsId}', id);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.selectedNews = response ? response.data : {};
          resolve(this.selectedNews);
        });
    });
  }

  @action cleanUp() {
    this.filters = this.defaultFilters;
    this.items = [];
    this.isFetched = false;
    this.featured = {};
    this.cleanUpDetail();
  }

  @action cleanUpDetail() {
    this.selectedNews = {};
  }

  /* HELPERS --------------------------- */

  isNews = i => i.categoryLabel === newsCategories.NEWS;
  isAlert = i => i.categoryLabel === newsCategories.ALERT;
  isHighlight = i => i.categoryLabel === newsCategories.HIGHLIGHT;
  isFeatured = i => i.categoryLabel === newsCategories.FEATURED;

  getWeight = item => (this.isAlert(item) ? -3
    : this.isFeatured(item) ? -2
      : this.isHighlight(item) ? -1 : 0);

  sortItems = items => toJS(items).sort((x, y) => {
    const weightX = this.getWeight(x);
    const weightY = this.getWeight(y);

    return (weightX < weightY) ? -1
      : (weightX > weightY) ? 1
        : (x.publishedAt > y.publishedAt) ? -1
          : (x.publishedAt < y.publishedAt) ? 1 : 0;
  });
}
