import { routes, labels } from '..';

export function getRoute(route, level) {
  const split = route.split('/');
  let res = '';
  for (let i = 1; i <= level; i += 1) {
    if (split[i]) {
      res += `/${split[i]}`;
    }
  }
  return res;
}

export function isRoute(route, currentRoute = window.location.pathname) {
  const idIndex = route.indexOf(':');
  const end = idIndex > 0 ? idIndex : route.length + 1;
  return currentRoute.startsWith(route.substr(0, end));
}

export function getTitleFromRoute(route) {
  switch (route) {
    case routes.ROOT:
    case routes.HOMEPAGE:
      return labels.HOMEPAGE;
    case routes.DOCUMENTI_BASE:
      return labels.DOCUMENTI;
    case routes.NEWS:
      return labels.NEWS;
    case routes.ORDINI:
      return labels.ORDINI;
    case routes.PROFILO:
      return labels.PROFILO;
    case routes.MATERIALI:
      return labels.MATERIALI;
    case routes.MATERIALI_GOTOMARKET:
      return labels.GOTO_MARKET;
    case routes.MATERIALI_FORMAZIONERETE:
      return labels.FORMAZIONE_RETE;
    case routes.MATERIALI_OPERATIVI:
      return labels.MATERIALI_OPERATIVI;
    case routes.MATERIALI_WEBINAR:
      return labels.WEBINAR;
    case routes.EVENTI:
      return labels.EVENTI;
    case routes.SUPPORTO_EDITORIALE:
      return labels.SUPPORTO_EDITORIALE;
    case routes.CERCA:
      return labels.CERCA;
    case routes.FAQ:
      return labels.FAQ;
    case routes.GESTIONE_UTENTI:
      return labels.GESTIONE_UTENTI;
    case routes.SCEGLI_E_PERSONALIZZA:
      return labels.SCEGLI_E_PERSONALIZZA;
    case routes.SCEGLI_E_PERSONALIZZA_GADGET:
      return labels.GADGET;
    case routes.SCEGLI_E_PERSONALIZZA_MERCHANDISING:
      return labels.MERCHANDISING;
    case routes.SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL:
      return labels.PROMOTIONAL_MATERIAL;
    default:
      if (isRoute(routes.NEWS_DETTAGLIO, route)) {
        return labels.NEWS;
      }
      if (isRoute(routes.MATERIALI_GOTOMARKET_CATEGORIA, route)
        || isRoute(routes.MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA, route)) {
        return labels.GOTO_MARKET;
      }
      if (isRoute(routes.MATERIALI_FORMAZIONERETE_CATEGORIA, route)
        || isRoute(routes.MATERIALI_FORMAZIONERETE_CATEGORIA_CAMPAGNA, route)) {
        return labels.FORMAZIONE_RETE;
      }
      if (isRoute(routes.MATERIALI_WEBINAR_CATEGORIA, route)
        || isRoute(routes.MATERIALI_WEBINAR_CATEGORIA_CAMPAGNA, route)) {
        return labels.WEBINAR;
      }
      if (isRoute(routes.EVENTI_CATEGORIA, route)
        || isRoute(routes.EVENTI_CATEGORIA_CAMPAGNA, route)) {
        return labels.EVENTI;
      }
      if (isRoute(routes.ORDINI_DETTAGLIO, route)) {
        return labels.ORDINI;
      }
      if (isRoute(routes.SCEGLI_E_PERSONALIZZA_GADGET_MATERIALE, route)
        || isRoute(routes.SCEGLI_E_PERSONALIZZA_GADGET_MATERIALE_CUSTOMIZE, route)) {
        return labels.GADGET;
      }
      if (isRoute(routes.SCEGLI_E_PERSONALIZZA_MERCHANDISING_MATERIALE, route)
        || isRoute(routes.SCEGLI_E_PERSONALIZZA_MERCHANDISING_MATERIALE_CUSTOMIZE, route)) {
        return labels.MERCHANDISING;
      }
      if (isRoute(routes.SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL_MATERIALE, route)
        || isRoute(routes.SCEGLI_E_PERSONALIZZA_PROMOTIONAL_MATERIAL_MATERIALE_CUSTOMIZE, route)) {
        return labels.PROMOTIONAL_MATERIAL;
      }
      return '';
  }
}
