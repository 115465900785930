import React from 'react';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { SearchOutlined, VideocamOutlined, DescriptionOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';

import { translationStore, labels, inputTypes, currentYear } from '../../../commons';
import { Form, Input, Button } from '../..';

// eslint-disable-next-line
const Filters = observer(({ title, filters, onChangeYear, onChangeSearch, onClickArticle, onClickVideo, mainButtonLabel, mainButtonOnClick, mainButtonIcon }) => {
  const {
    year,
    search,
    article,
    video,
  } = filters || {};

  return (
    <Row className="filters">
      {title ? (
        <Col className="title" md={4}>
          <h3>{title}</h3>
        </Col>
      ) : ''}
      {mainButtonLabel ? (
        <Button
          color="primary"
          className="main-btn"
          label={mainButtonLabel}
          onClick={mainButtonOnClick}
          icon={mainButtonIcon}
        />
      ) : ''}
      <Col>
        <Form inline className="pull-right">
          {onClickArticle || onClickVideo ? (
            <InputGroup className="filter-buttons">
              {onClickArticle ? (
                <Button
                  color="link"
                  className={article ? 'selected' : ''}
                  icon={DescriptionOutlined}
                  onClick={onClickArticle}
                />
              ) : ''}
              {onClickVideo ? (
                <Button
                  color="link"
                  className={video ? 'selected' : ''}
                  icon={VideocamOutlined}
                  onClick={onClickVideo}
                />
              ) : ''}
            </InputGroup>
          ) : ''}
          {onChangeYear ? (
            <InputGroup className="year">
              <Input
                type={inputTypes.NUMBER}
                value={year}
                onChange={onChangeYear}
                min={0}
                max={currentYear()}
              />
            </InputGroup>
          ) : ''}
          {onChangeSearch ? (
            <InputGroup className="search-filter">
              <Input
                type={inputTypes.TEXT}
                placeholder={translationStore.get(labels.CERCA)}
                value={search}
                onChange={onChangeSearch}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <SearchOutlined />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          ) : ''}
        </Form>
      </Col>
    </Row>
  );
});

export default Filters;
