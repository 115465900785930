import { observable, action, computed } from 'mobx';
import { toast } from 'react-toastify';

import { api, apiRoutes, downloadFile, buildUrl, translationStore, labels } from '..';

export default class HelpStore {
  @observable requestDetail = {};
  @observable loadingRequestDetail = false;

  @action fetchRequestDetail(id) {
    this.loadingRequestDetail = true;
    const query = apiRoutes.HELP_DETAIL
      .replace('{helpRequestId}', id);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.requestDetail = response ? response.data : {};
          this.loadingRequestDetail = false;
          resolve(this.requestDetail);
          let helpChat = document.getElementsByClassName("help-chat")[0]
          if (helpChat) {
            helpChat.scrollTo(0, helpChat.scrollHeight)
          }
        });
    });
  }

  @computed get detailIsFetched() {
    return Object.keys(this.requestDetail).length !== 0
      && this.requestDetail.constructor === Object;
  }

  /* HELP CHAT ------------------------------------- */

  baseFields = {
    message: '',
    attachments: [],
    files: [],
  };
  @observable fields = this.baseFields;
  @observable loadingReply = false;
  @observable loadingAttachments = false;

  @action setMessage(message) {
    this.fields.message = message;
  }

  @action addAttachments(id) {
    this.fields.attachments.push({ attachmentId: id });
    if (this.fields.attachments.length === this.fields.files.length) {
      this.loadingAttachments = false;
    }
  }

  @action setFiles(files, loading = false) {
    this.fields.files = files;
    this.fields.attachments = [];
    if (loading) {
      this.loadingAttachments = true;
    }
  }

  @action replyRequest() {
    if (this.fields.message
      || (this.fields.message && this.fields.attachments.length)) {
      this.loadingReply = true;
      const query = apiRoutes.HELP_REPLY
        .replace('{helpRequestId}', this.requestDetail.id);

      api
        .post(query, {
          categoryId: this.requestDetail.categoryId,
          message: this.fields.message,
          attachments: this.fields.attachments,
        })
        .then(() => {
          this.cleanUpFields();
          this.loadingReply = false;
          this.fetchRequestDetail(this.requestDetail.id);
        });
    }
  }

  /* NEED HELP --------------------------------------- */

  sendRequest = (categoryId, message, attachments) => (
    new Promise(resolve => api
      .post(apiRoutes.HELP, {
        categoryId,
        message,
        attachments,
      })
      .then(() => {
        resolve(true);
        toast.success(translationStore.get(labels.HELP_SUCCESS_MSG));
      }))
  );

  /* HELPERS --------------------------------------- */

  downloadAttachment = (attachmentId, attachmentName) => {
    const query = buildUrl(apiRoutes.FILE
      .replace('{fileId}', attachmentId));

    downloadFile(api, query, attachmentName);
  }

  @action cleanUp() {
    this.items = [];
    this.isFetched = false;
    this.requestDetail = {};
    this.cleanUpFields();
  }

  @action cleanUpFields() {
    this.fields = this.baseFields;
  }

  /* FAQ */

  @observable faqs = [];
  @observable loadingFaqs = false;

  @action fetchFaqs() {
    this.loadingFaqs = true;
    return new Promise((resolve) => {
      api
        .get(apiRoutes.FAQ)
        .then((response) => {
          this.faqs = response ? response.data.data : [];
          this.loadingFaqs = false;
          resolve(true);
        });
    });
  }

  @computed get isFetchedFaqs() {
    return this.faqs && this.faqs.length > 0;
  }

  @observable selectedCategoryId = '';

  @action setSelectedCategoryId(categoryId) {
    this.selectedCategoryId = categoryId;
  }

  @computed get hasFaqs() {
    return this.isFetchedFaqs && this.faqs
      .some(faq => faq.categories && faq.categories
        .some(c => c.categoryId === this.selectedCategoryId));
  }
}
