import { action, observable, computed } from 'mobx';

import { api, apiRoutes, isImage, isHtml, customizeStore, campaignIds } from '..';

export default class GadgetStore {

  @observable gadgets = {};

  @computed get isFetched() {
    return Object.keys(this.gadgets).length !== 0
      && this.gadgets.constructor === Object;
  }

  @computed get noMaterials() {
    return this.gadgets.materials && this.gadgets.materials.length === 0;
  }

  @action fetchGadgets(routesCheck) {
    const query = apiRoutes.CAMPAIGN_DETAIL
      .replace('{campaignId}',
        routesCheck.gadget
          ? campaignIds.GADGET
          : (routesCheck.merchandising
            ? campaignIds.MERCHANDISING
            : campaignIds.PROMOTIONAL_MATERIAL));

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.gadgets = response ? response.data : {};
          resolve(this.gadgets);
        });
    });
  }

  @action cleanUpGadgets() {
    this.gadgets = {};
  }

  /*  DETTAGLIO */

  @observable selectedGadget = {};

  @observable isFetchedGadgetDetail = false;

  buildTemplateUrl = (material = this.selectedGadget) => {
    const type = material.mainTemplateContentType;
    const isPdf = !(isImage(type) || isHtml(type));
    const route = isPdf ? apiRoutes.MATERIAL_CUSTOMIZE_PDF : apiRoutes.MATERIAL_CUSTOMIZE;
    const query = route.replace('{materialId}', material.id);

    let qs = '';
    material.parameters
      .filter((p) => customizeStore.checkCustomization(p.customizationTypeLabel))
      .forEach((parameter, i) => {
        qs += i === 0 ? `?customizationType=${parameter.customizationType}` : '';
        qs += `&${parameter.name}=${parameter.value || 'null'}`;
      });
    return query.replace('{qs}', isPdf ? encodeURIComponent(qs) : qs);
  };

  @action fetchGadgetDetail(materialId) {
    const query = apiRoutes.MATERIAL_DETAIL.replace('{materialId}', materialId);

    return new Promise((resolve) => {
      api.get(query).then((response) => {
        this.selectedGadget = response ? { ...response.data, img: this.buildTemplateUrl(response.data) } : {};
        this.isFetchedGadgetDetail = true;
        resolve(this.selectedGadget);
      });
    });
  }
}
