import { observable, action, toJS } from 'mobx';

import { apiRoutes, api, userStore, checkValidNumber } from '..';

export default class ProfileStore {
  @observable referent = {};
  referentBase = {};

  @action setReferent(referent) {
    this.referent = referent;
    this.referentBase = toJS(this.referent);
  }

  /* EDITING --------------------------------------------------------------- */

  @observable disabledEdit = true;
  @observable disabledUndo = true;
  @observable disabledSave = true;
  @observable isValid = {
    mobilePhoneNumber: true,
    phoneNumber: true,
  };

  @action startEdit() {
    this.disabledEdit = false;
    this.disabledUndo = false;
  }

  @action undoEdit() {
    this.disabledEdit = true;
    this.disabledUndo = true;
    this.disabledSave = true;
    this.isValid.mobilePhoneNumber = true;
    this.isValid.phoneNumber = true;
    this.referent = this.referentBase;
  }

  @action saveEdit() {
    api
      .put(apiRoutes.USER, this.referent)
      .then((response) => {
        this.referent = response.data ? response.data : {};
        this.referentBase = toJS(this.referent);
        this.undoEdit();
        userStore.fetchUserInfo();
      });
  }

  /* NAME & SURNAME --------------------------------------------------------- */

  @action setFirstName(firstName) {
    this.referent.firstName = firstName;
    this.disabledSave = false;
    this.disabledUndo = false;
  }

  @action setLastName(lastName) {
    this.referent.lastName = lastName;
    this.disabledSave = false;
    this.disabledUndo = false;
  }

  /* PHONE NUMBER ----------------------------------------------------------- */

  @action setMobilePhoneNumber(mobilePhoneNumber) {
    this.referent.mobilePhoneNumber = mobilePhoneNumber;
    const isValid = checkValidNumber(mobilePhoneNumber);
    this.isValid.mobilePhoneNumber = isValid;
    this.disabledUndo = false;
    this.disabledSave = !isValid;
  }

  @action setPhoneNumber(phoneNumber) {
    this.referent.phoneNumber = phoneNumber;
    const isValid = checkValidNumber(phoneNumber);
    this.isValid.phoneNumber = isValid;
    this.disabledUndo = false;
    this.disabledSave = !isValid;
  }

  /* SELECT ----------------------------------------------------------------- */

  @action changeShippingSite(siteId) {
    this.referent.branch.shippingSites = toJS(this.referent.branch.shippingSites)
      .sort((x, y) => (x.id === siteId ? -1 : (y.id === siteId ? 1 : 0)))
      .map((s) => {
        s.preferred = (s.id === siteId);
        return s;
      });
    this.disabledSave = false;
    this.disabledUndo = false;
  }

  /* DYNAMIC-INPUT ---------------------------------------------------------- */

  @action removeShippingSite(address) {
    this.referent.branch.shippingSites = this.referent.branch.shippingSites
      .filter(s => s.addressFreeform !== address);
    this.disabledSave = this.referent.branch.shippingSites.length === 0;
  }

  @action addShippingSite(site) {
    this.referent.branch.shippingSites.push(site);
    this.disabledSave = false;
  }

  @action updateShippingSite(site, oldSite) {
    this.referent.branch.shippingSites = this.referent.branch.shippingSites
      .map((s) => {
        if (s.addressFreeform === oldSite.addressFreeform) {
          const si = site;
          si.id = oldSite.id;
          return si;
        }
        return s;
      });
    this.disabledSave = false;
  }
}
