import { action, observable, computed } from 'mobx';

import { api, apiRoutes, queryBuilder, getVideoPreview, campaignCategories, campaignCategoriesFormazioneRete, campaignCategoriesWebinar, campaignCategoriesEventi, campaignCategoriesNexi, userStore } from '..';
import categoryCards from '../../assets/images/campaigns/campaign-category-1.jpg';
import categoryMerchant from '../../assets/images/campaigns/campaign-category-2.png';
import categoryMobile from '../../assets/images/campaigns/campaign-category-3.jpg';
import categoryIniziative from '../../assets/images/campaigns/campaign-category-4.jpg';
import categoryCardsDigital from '../../assets/images/campaigns/campaign-category-5.jpg';
import categoryMerchantSolutions from '../../assets/images/campaigns/campaign-category-6.jpg';
import categoryBankingSolutions from '../../assets/images/campaigns/campaign-category-11.jpg';
import categoryPublicAdmin from '../../assets/images/campaigns/campaign-category-12.jpg';
import categorySustainability from '../../assets/images/campaigns/campaign-category-13.png';
import categorySecurity from '../../assets/images/campaigns/campaign-category-17.png';

// TODO: DEMO
import categoryDemo1 from '../../assets/images/campaigns/campaign-category-demo1.jpg';
import categoryDemo2 from '../../assets/images/campaigns/campaign-category-demo2.jpg';

export default class CampaignStore {
  @observable items = [];
  @observable isFetched = false;
  defaultFilters = {
    search: '',
    category: '',
    categoryLabel: '',
  };
  @observable filters = this.defaultFilters;
  defaultSkip = 0;
  defaultTake = 100;

  @computed get noCategories() {
    return this.isFetched && this.categories.peek().length === 0;
  }

  @computed get noCampaigns() {
    return this.isFetched && this.items.peek().length === 0;
  }

  @computed get noMaterials() {
    return this.selectedCampaign.materials
      && this.selectedCampaign.materials.length === 0;
  }

  @action fetchCampaigns(skip = this.defaultSkip, take = this.defaultTake) {
    this.isFetched = false;
    this.items = [];

    return new Promise(async (resolve) => {
      const category = this.categories
        .filter(c => (c.categoryId === this.filters.category)
          || (c.categoryLabel === this.filters.categoryLabel))[0];
      if (category) {
        this.items = category.children;
        this.items = await getVideoPreview(this.items, 'coverVideoUrl');
        this.isFetched = true;
        resolve(category.label);
      }
      const query = queryBuilder(skip, take, this.filters);
      api
        .get(`${apiRoutes.CAMPAIGNS}?${query}`)
        .then(async (response) => {
          this.items = response ? response.data.data : [];

          // TODO: LEVA QUESTA CACATA
          this.items = this.items.filter(i => i.id !== '78037097-6187-4e20-7a0e-08d6919b4b3f');

          /* VIDEO PREVIEW */
          this.items = await getVideoPreview(this.items, 'coverVideoUrl');

          if (userStore.isReferral) {
            this.items = this.items
              .filter(i => !campaignCategoriesFormazioneRete.
                includes(i.categoryLabel));
          } else if (userStore.isNexi) {
            this.items = this.items
              .filter(i => campaignCategoriesNexi.
                includes(i.categoryLabel));
          }

          this.isFetched = true;
          resolve(response.data.categoryLabels[0]);
        });
    });
  }

  @action filterBySearch(search) {
    this.filters.search = search;
    this.fetchCampaigns();
  }

  @action filterByCategory(category) {
    this.filters.category = category;
    return this.fetchCampaigns();
  }

  @action filterByCategoryLabel(categoryLabel) {
    this.filters.categoryLabel = categoryLabel;
    return this.fetchCampaigns();
  }

  /* CAMPAGINS CATEGORIES */

  @observable categories = [];
  @observable isFetchedCategories = false;

  @action fetchCategories({ gotoMarket, formazioneRete, webinar, eventi }) {
    this.isFetchedCategories = false;
    this.categories = [];

    return new Promise((resolve) => {
      api
        .get(apiRoutes.CATEGORIES.replace('{entityName}', 'campaign'))
        .then((response) => {
          if (response) {
            const res = response.data.data
              .filter((c) => {
                const isFormazioneRete = campaignCategoriesFormazioneRete.includes(c.label);
                const isWebinar = campaignCategoriesWebinar.includes(c.label);
                const isEventi = campaignCategoriesEventi.includes(c.label);
                return (gotoMarket && !isFormazioneRete && !isWebinar && !isEventi)
                  || (formazioneRete && isFormazioneRete)
                  || (webinar && isWebinar)
                  || (eventi && isEventi);
              });
            res
              .forEach((c, i) => {
                this.filters.category = c.id;
                this.fetchCampaigns()
                  .then(() => {
                    this.categories.push({
                      categoryId: c.id,
                      label: c.label,
                      img: this.getImageFromCategory(c.label),
                      children: this.items,
                    });
                    if (i === res.length - 1) {
                      this.isFetchedCategories = true;
                      resolve(true);
                    }
                  });
              });
            if (res.length === 0) {
              this.isFetchedCategories = true;
              resolve(true);
            }
          }
        });
    });
  }

  /* CAMPAIGN DETAIL */

  @observable selectedCampaign = {};

  @computed get detailIsFetched() {
    return Object.keys(this.selectedCampaign).length !== 0
      && this.selectedCampaign.constructor === Object;
  }

  @action fetchCampaignDetail(campaignId) {
    const query = apiRoutes.CAMPAIGN_DETAIL
      .replace('{campaignId}', campaignId);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.selectedCampaign = response ? response.data : {};
          resolve(this.selectedCampaign);
        });
    });
  }

  @action cleanUp() {
    this.filters = this.defaultFilters;
    this.items = [];
    this.isFetched = false;
    this.categories = [];
    this.cleanUpDetail();
  }

  @action cleanUpDetail() {
    this.selectedCampaign = {};
  }

  /* HELPERS */

  getImageFromCategory = (categoryLabel) => {
    switch (categoryLabel) {
      case campaignCategories.CARDS:
        return categoryCards;
      case campaignCategories.MERCHANT:
        return categoryMerchant;
      case campaignCategories.MOBILE:
        return categoryMobile;
      case campaignCategories.INIZIATIVE:
        return categoryIniziative;
      case campaignCategories.BANKING_SOLUTIONS:
        return categoryBankingSolutions;
      case campaignCategories.PUBLIC_ADMIN:
        return categoryPublicAdmin;
      case campaignCategories.SUSTAINABILITY:
        return categorySustainability;
      case campaignCategories.SECURITY:
        return categorySecurity;
      // FORMAZIONE RETE
      case campaignCategories.CARDS_DIGITAL:
        return categoryCardsDigital;
      case campaignCategories.MERCHANT_SOLUTIONS:
        return categoryMerchantSolutions;
      // TODO: DEMO
      case campaignCategories.DEMO1:
        return categoryDemo1;
      case campaignCategories.DEMO2:
        return categoryDemo2;
      default:
        return null;
    }
  }
}
