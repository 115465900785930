import React from 'react';
import { Row, Col } from 'reactstrap';

import { translationStore, labels } from '../../commons';

const NoData = ({ isTable }) => (
  <Row className={`no-data${isTable ? '-table' : ''}`}>
    <Col className="text-center">
      <h4>{translationStore.get(labels.NO_DATA)}</h4>
    </Col>
  </Row>
);

export default NoData;
