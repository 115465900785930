import { observable, action } from 'mobx';

export default class PreviewStore {
  @observable isVisible = false;
  @observable isVideo = false;
  @observable src = '';

  @action toggle() {
    this.isVisible = !this.isVisible;
  }

  @action open(src, isVideo = false) {
    this.src = src;
    this.isVideo = isVideo;
    this.toggle();
  }

  @action cleanUp() {
    this.isVisible = false;
    this.isVideo = false;
    this.src = '';
  }
}
