import axios from 'axios';

import { formatDateForBE } from '..';

export function buildUrl(route) {
  return `${window.location.origin}/admin/api/portal${route}`;
}

export function queryBuilder(skip, take, filters = {}, home = false, editorials = false) {
  let query = `skip=${skip}&take=${take}`;

  const {
    /* COMMON */
    year,
    search,
    /* NEWS */
    categories,
    article,
    video,
    /* CAMPAIGNS */
    category,
  } = filters;

  query += search ? `&search=${search}` : '';

  if (year && !home) {
    const fromDate = formatDateForBE(new Date(year, 0, 1));
    const toDate = formatDateForBE(new Date(parseInt(year, 10) + 1, 0, 1));
    query += `&fromDate=${fromDate}&toDate=${toDate}`;
  }

  if (categories) {
    query += '&categoryLabels=';
    query += categories.filter((c, i) => (editorials ? i === 0 : i !== 0));
    query = query.replace(/,\s*$/, '');
  }

  query += article || video ? `&hasVideoCover=${video}` : '';
  query += category ? `&categoryId=${category}` : '';

  return query;
}

export async function getVideoPreview(items, field) {
  const res = items.map(i => i);
  const loop = () => new Promise((resolve) => {
    items
      .forEach(async (item, i) => {
        const url = item[field];
        if (url) {
          /* YOUTUBE */
          let video = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

          if (video) {
            item[field] = `https://img.youtube.com/vi/${video[1]}/hqdefault.jpg`;
          } else {
            /* VIMEO */
            video = url.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i);

            if (video) {
              const response = await axios.get(`https://vimeo.com/api/v2/video/${video[1]}.json`);
              item[field] = response.data[0].thumbnail_large;
            }
          }
          res[i] = item;
        }
      });

    resolve(true);
  });

  await loop();
  return res;
}
