import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import { translationStore, labels, apiRoutes, api } from '../../../commons';
import { Button } from '../..';

// https://github.com/react-dropzone/react-dropzone

export default class FileUploader extends Component {
  state = {
    files: this.props.files || [],
    loading: false,
  };

  static getDerivedStateFromProps(nextProps) {
    return ({ files: nextProps.files || [] });
  }

  render() {
    const {
      state: { files, loading },
      props: { onChange, onLoad, disabled },
    } = this;

    return (
      <Dropzone
        multiple
        noClick
        onDrop={(acceptedFiles) => {
          if (!disabled && !loading) {
            this.setState({ files: acceptedFiles, loading: true });
            if (onLoad) {
              onLoad(acceptedFiles);
            }
            acceptedFiles.forEach((file, i) => {
              const data = new FormData();
              data.append('file', file, file.name);

              api
                .post(apiRoutes.FILE_UPLOAD, data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                  onChange(response.data.id);
                  if (i === acceptedFiles.length - 1) {
                    this.setState({ loading: false });
                  }
                });
            });
          }
        }}
      >
        {({ getRootProps, getInputProps, open }) => (
          <div {...getRootProps()} className="file-uploader">
            <input {...getInputProps()} />
            <div title={`${files && JSON.stringify(files.map(f => f.name))}`}>
              {files && files.length
                ? JSON.stringify(files.map(f => f.name)) : 'C:'}
            </div>
            <Button
              outline
              color="primary"
              label={translationStore.get(labels.SFOGLIA)}
              disabled={disabled}
              onClick={() => open()}
            />
          </div>
        )}
      </Dropzone>
    );
  }
}
