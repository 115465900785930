/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Container as Cntnr } from 'reactstrap';

const Container = props => (
  <Cntnr fluid className={`${props.className ? props.className : ''} page-container`}>
    {props.children}
    <div id="powered-by">
      Powered by
      {'  '}
      <a href="http://www.sharize.io" target="_blank" rel="noopener noreferrer">Sharize</a>
    </div>
  </Cntnr>
);

export default Container;
