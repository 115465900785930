import React, { Component } from 'react';
import { Modal as Mdl, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { labels, translationStore, inputTypes } from '../../commons';
import { Button, Input } from '..';

export default class Modal extends Component {
  state = {
    motivation: '',
  };

  setMotivation(motivation) {
    this.setState({ motivation });
  }

  render() {
    const {
      isOpen, toggle, message, className, hasMotivation,
      confirmLabel,
      undoLabel,
    } = this.props;

    return (
      <Mdl
        isOpen={isOpen}
        toggle={() => toggle(false)}
        className={className}
      >
        <ModalHeader toggle={() => toggle(false)}>
          {`${translationStore.get(labels.ATTENZIONE.toUpperCase())}!`}
        </ModalHeader>
        <ModalBody>
          {translationStore.get(message || labels.CONFERMA_QUANTITA_DISCLAIMER)}
          {hasMotivation ? (
            <form>
              <div className="form-group">
                <Input
                  type={inputTypes.TEXT}
                  label={`${translationStore.get(labels.MOTIVAZIONE)}: *`}
                  onChange={e => this.setMotivation(e.target.value)}
                  half
                />
              </div>
            </form>
          ) : ''}
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={() => toggle(false)}
            label={translationStore.get(undoLabel || labels.ANNULLA)}
          />
          {' '}
          <Button
            color="primary"
            onClick={() => toggle(hasMotivation ? this.state.motivation : true)}
            label={translationStore.get(confirmLabel || labels.CONFERMA)}
            disabled={hasMotivation && !this.state.motivation}
          />
        </ModalFooter>
      </Mdl>
    );
  }
}
