import React from 'react';

const ImageBackground = ({ className, img, children }) => (
  <div
    className={className}
    style={{
      backgroundImage: `url(${img})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      opacity: 0.8,
      position: 'relative',
      maxHeight: '45vh',
    }}
  >
    {children}
  </div>
);

export default ImageBackground;
