import { observable, action, computed } from 'mobx';

export default class PathStore {
  @observable path = []

  @action push(item) {
    this.pop(item);
    this.path.push(item);
  }

  @action pop(item) {
    const i = item ? (item.level ? item.level : 0) : this.path.length - 1;
    this.path.splice(i, this.path.length);
  }

  @computed get last() {
    return this.path.length - 1;
  }

  @action cleanUp() {
    this.path = [];
  }
}
