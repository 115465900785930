import React from 'react';
import { ToastContainer } from 'react-toastify';

// https://github.com/fkhadra/react-toastify

const Toast = () => (
  <ToastContainer
    className="toast-container"
    autoClose={3000}
    draggable={false}
    toastClassName="toast"
    bodyClassName="toast-body"
    pauseOnHover={false}
    pauseOnFocusLoss={false}
  />
);

export default Toast;
