import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  toggle = tab => (this.state.activeTab !== tab
    && this.setState({
      activeTab: tab,
    }));

  render() {
    const { tabs } = this.props;
    return (
      <>
        <Nav tabs>
          {tabs.map((tab, i) => (
            <NavItem key={tab.title}>
              <NavLink
                className={this.state.activeTab === (i) ? 'active' : ''}
                onClick={() => { this.toggle(i); }}
              >
                {tab.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {tabs.map((tab, i) => (
            <TabPane tabId={i} key={`${tab.title}-${i}`}>
              <tab.component
                changeTab={() => { this.toggle(i + 1); }}
              />
            </TabPane>
          ))}
        </TabContent>
      </>
    );
  }
}
