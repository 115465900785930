export function objectToArray(object) {
  return Object.entries(object).map(([, v]) => v);
}

export function groupBy(array, property) {
  return objectToArray(array.reduce((a, b) => {
    (a[b[property]] = a[b[property]] || []).push(b);
    return a;
  }, {}));
}

export function orderBy(array, property, inverse = false) {
  return array.sort((a, b) => (
    (a[property] > b[property])
      ? (inverse ? -1 : 1)
      : ((b[property] > a[property])
        ? (inverse ? 1 : -1) : 0)
  ));
}
