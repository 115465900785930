import React from 'react';
import ReactPlayer from 'react-player';

// https://github.com/CookPete/react-player

const VideoPlayer = ({ url }) => (
  <ReactPlayer
    url={url}
    className="video-player"
    height="100%"
    width="100%"
    controls
  />
);

export default VideoPlayer;
