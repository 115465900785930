import React from 'react';
import { observer } from 'mobx-react';

import { translationStore, labels } from '../../commons';

import { Button } from '..';

const ShowMore = observer(({ loading, onClick }) => {
  return (
    <div className="show-more">
      <Button
        color="link"
        label={translationStore.get(labels.VISUALIZZA_PIU)}
        withLoading
        loading={loading}
        onClick={onClick}
      />
    </div>
  );
});

export default ShowMore;
