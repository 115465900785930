import { action, observable, computed, toJS } from 'mobx';

import { apiRoutes, api, isPdf, downloadFile, buildUrl, fileTypes } from '..';

export default class DocumentStore {
  originalItems = [];
  @observable items = [];
  @observable isFetched = false;

  @computed get isEmpty() {
    return this.items.peek().length === 0;
  }

  @computed get tree() {
    return toJS(this.items);
  }

  @action fetchTree() {
    this.isFetched = false;
    this.items = [];
    api
      .get(`${apiRoutes.DOCUMENTS_TREE}00000000-0000-0000-0000-000000000000`)
      .then((response) => {
        this.originalItems = response.data;
        this.items = this.mapItems(response.data);
        this.isFetched = true;
      });
  }

  mapItems = (items = this.originalItems, level = 0) => {
    const x = this;
    if (items && items.length) {
      return items.map((item) => {
        const mappedItem = {
          id: item.id,
          parentId: item.parentId,
          label: item.name,
          type: item.type,
          contentType: item.contentType,
          fileId: item.fileId,
          level,
          children: this.mapItems(item.children, level + 1),
        };
        if ((x.itemId && (x.itemId === item.id))
          || (!x.itemId && (x.directoryId === item.id))) {
          x.setSelectedItem(mappedItem);
        }
        return mappedItem;
      });
    }
    return undefined;
  }

  directoryId = '';
  itemId = '';

  @action setSelectedItemId = ({ directoryId, itemId }) => {
    this.directoryId = directoryId;
    this.itemId = itemId;
    if (this.isFetched) {
      this.mapItems(this.originalItems);
    }
    this.setLastDirectoryId(directoryId);
  }

  @observable lastDirectoryId = '';

  @action setLastDirectoryId(directoryId) {
    this.lastDirectoryId = directoryId;
  }

  @observable selectedItem = {};

  @action setSelectedItem(item) {
    let query = '';
    if (item.type === fileTypes.FILE) {
      const route = isPdf(item.contentType)
        ? apiRoutes.PDF : buildUrl(apiRoutes.FILE_PREVIEW);
      query = route
        .replace('{fileId}', item.fileId);
    }

    this.selectedItem = {
      ...item,
      src: query,
    };
  }

  @computed get selectedItemIsFetched() {
    return Object.keys(this.selectedItem).length !== 0
      && this.selectedItem.constructor === Object;
  }

  @action downloadDocument() {
    const query = buildUrl(apiRoutes.FILE
      .replace('{fileId}', this.selectedItem.fileId));

    downloadFile(api, query, this.selectedItem.label);
  }

  @action cleanSelection() {
    this.directoryId = '';
    this.itemId = '';
    this.selectedItem = {};
  }

  @action cleanUp() {
    this.items = [];
    this.cleanSelection();
  }
}
