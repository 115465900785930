import React, { Component } from 'react';
import { Table as Tbl, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { FirstPageOutlined, LastPageOutlined, ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons';

import { translationStore } from '../../commons';
import { NoData, Loading } from '..';

@observer
export default class Table extends Component {
  state = {
    skip: this.props.filters
      ? this.props.filters.skip
      : 0,
    take: this.props.filters
      ? this.props.filters.take
      : (this.props.pageable ? 10 : this.props.items.length),
    pageCount: Math.ceil(this.props.filters
      ? this.props.filters.total / this.props.filters.take
      : this.props.items.length / 10),
    currentPage: this.props.filters
      ? this.props.filters.currentPage
      : 1,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.filters
      && (nextProps.filters.pageCount !== prevState.pageCount
        || nextProps.filters.currentPage !== prevState.currentPage)) {
      return ({
        pageCount: Math.ceil(nextProps.filters.total / nextProps.filters.take),
        currentPage: nextProps.filters.currentPage,
      });
    }
    return ({});
  }

  first = () => {
    const { filters, onPageChange } = this.props;
    if (filters) {
      onPageChange(0, 1);
    } else {
      this.setState({
        skip: 0,
        currentPage: 1,
      });
    }
  };

  last = () => {
    const {
      props: { filters, onPageChange },
      state: { pageCount },
    } = this;
    if (filters) {
      onPageChange(filters.total - filters.take, pageCount);
    } else {
      this.setState(s => ({
        skip: this.props.items.length - s.take,
        currentPage: s.pageCount,
      }));
    }
  };

  prev = () => {
    const { filters, onPageChange } = this.props;
    if (filters) {
      onPageChange(filters.skip - filters.take, filters.currentPage - 1);
    } else {
      this.setState(s => ({
        skip: s.skip - s.take,
        currentPage: s.currentPage - 1,
      }));
    }
  };

  next = () => {
    const { filters, onPageChange } = this.props;
    if (filters) {
      onPageChange(filters.skip + filters.take, filters.currentPage + 1);
    } else {
      this.setState(s => ({
        skip: s.skip + s.take,
        currentPage: s.currentPage + 1,
      }));
    }
  };

  goto = (currentPage) => {
    const { filters, onPageChange } = this.props;
    if (filters) {
      onPageChange(filters.take * (currentPage - 1), currentPage);
    } else {
      this.setState(s => ({
        skip: s.take * (currentPage - 1),
        currentPage,
      }));
    }
  }

  render() {
    const {
      state: { skip, take, pageCount, currentPage },
      props: { className, columns, items, pageable, loading },
      first, last, prev, next, goto,
    } = this;

    const disabledPrev = currentPage === 1;
    const disabledNext = currentPage === pageCount;

    return (
      <div className="table-wrapper">
        <Tbl responsive className={className}>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.name}>
                  {translationStore.get(column.title)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!loading && items
              .slice(skip, (skip + take))
              .map(item => (
                <tr key={item.id}>
                  {columns.map(column => (
                    <td key={`${item.id}-${column.name}`}>
                      <span>
                        {column.render ? column.render(item) : item[column.name]}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </Tbl>
        {loading
          ? <Loading />
          : !items.length && !loading ? <NoData isTable /> : ''}
        {pageable && (
          <Pagination>
            <PaginationItem onClick={disabledPrev ? undefined : first} disabled={disabledPrev}>
              <PaginationLink>
                <FirstPageOutlined />
              </PaginationLink>
            </PaginationItem>
            <PaginationItem onClick={disabledPrev ? undefined : prev} disabled={disabledPrev}>
              <PaginationLink>
                <ChevronLeftOutlined />
              </PaginationLink>
            </PaginationItem>
            {_.range(0, pageCount).map((p, i) => (
              <PaginationItem
                key={`page-${i}`}
                className={currentPage === i + 1 ? 'current' : ''}
                onClick={() => { goto(i + 1); }}
              >
                <PaginationLink>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem onClick={disabledNext ? undefined : next} disabled={disabledNext}>
              <PaginationLink>
                <ChevronRightOutlined />
              </PaginationLink>
            </PaginationItem>
            <PaginationItem onClick={disabledNext ? undefined : last} disabled={disabledNext}>
              <PaginationLink>
                <LastPageOutlined />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        )}
      </div>
    );
  }
}
