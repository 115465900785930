import React from 'react';
import { Form as Frm } from 'reactstrap';

const Form = ({ className, inline, children }) => (
  <Frm
    className={className}
    inline={inline}
  >
    {children}
  </Frm>
);

export default Form;
