import { action, observable, computed } from 'mobx';

import { sourceType, history, routes, labels, documentStore, api, apiRoutes } from '..';

export default class SearchStore {
  @observable items = [];

  @action setItems(items) {
    this.items = items;
  }

  @computed get hasItems() {
    return this.items.length > 0;
  }

  search = '';
  @observable loading = false;
  @action fetchSearch() {
    this.loading = true;
    return new Promise((resolve) => {
      const { lastDirectoryId } = documentStore;
      api
        .get(`${apiRoutes.SEARCH}?take=20${lastDirectoryId ? `&descendantOf=${lastDirectoryId}&source=2` : ''}&partialInput=${this.search}`)
        .then((res) => {
          this.loading = false;
          resolve(res.data.data);
        });
    })
  }

  /* HELPERS */

  handleNavigation({ item, source, id }, items = [], search = '') {
    switch (source) {
      case sourceType.NONE:
        this.setItems(items.slice(1, items.length));
        if (search !== this.search) {
          this.search = search;
          this
            .fetchSearch(search)
            .then((res) => {
              this.setItems(res);
            });
        }
        history.push(routes.CERCA);
        break;
      case sourceType.NEWS:
        history.push(
          routes.NEWS_DETTAGLIO
            .replace(':newsId', id),
        );
        break;
      case sourceType.DOCUMENT:
        documentStore.cleanSelection();
        history.push(
          routes.DOCUMENTI_DETTAGLIO_DOCUMENTO
            .replace(':directoryId', item.parentId)
            .replace(':itemId', id),
        );
        break;
      case sourceType.CAMPAIGN:
        history.push(
          routes.MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA
            .replace(':categoryId', item.categoryId)
            .replace(':campaignId', id),
        );
        break;
      case sourceType.ORDER:
        history.push(
          routes.ORDINI_DETTAGLIO
            .replace(':orderId', id),
        );
        break;
      case sourceType.MATERIAL:
        history.push(
          routes.MATERIALI_GOTOMARKET_CATEGORIA_CAMPAGNA_MATERIALE
            .replace(':categoryId', item.categoryId)
            .replace(':campaignId', item.campaignId)
            .replace(':materialId', id),
        );
        break;
      default:
        break;
    }
  }

  getLabelFromSource = (source) => {
    switch (source) {
      case sourceType.NEWS:
        return labels.NEWS;
      case sourceType.CAMPAIGN:
        return labels.CAMPAGNE;
      case sourceType.ORDER:
        return labels.ORDINI;
      case sourceType.MATERIAL:
        return labels.MATERIALI;
      case sourceType.DOCUMENT:
        return labels.DOCUMENTI;
      case sourceType.NONE:
      default:
        return '';
    }
  }
}
