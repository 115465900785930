import { observable, action, computed, toJS } from 'mobx';

import { api, apiRoutes, queryBuilder, orderStatus, buildUrl, getVideoPreview, downloadFile, customizationTypes } from '..';
import { sequentialGet } from '../api';

export default class OrderStore {
  @observable items = [];
  @observable isFetched = false;
  defaultFilters = {
    search: '',
  };
  @observable filters = this.defaultFilters;
  defaultSkip = 0;
  defaultTake = 20;

  @observable hasShowMore = false;
  @observable loading = false;
  countSkip = this.defaultSkip;

  @computed get noOrders() {
    return this.isFetched && this.items.peek().length === 0;
  }

  @action fetchOrders(skip = this.defaultSkip, take = this.defaultTake, home = false, showMore = false) {
    if (!showMore) {
      this.isFetched = false;
      this.items = [];
    } else {
      this.loading = true;
    }

    const query = queryBuilder(skip, take, this.filters, home);
    sequentialGet(`${apiRoutes.ORDERS}?${query}`)
      .then(async (response) => {
        let items = response ? response.data.data : [];

        /* VIDEO PREVIEW */
        items = await getVideoPreview(items, 'campaignCoverVideoUrl');

        this.hasShowMore = response.data.filteredCount > (this.countSkip + this.defaultTake);

        if (!showMore) {
          this.items = items;
          this.isFetched = true;
        } else {
          this.items = this.items.concat(items);
          this.countSkip += this.defaultTake;
          this.loading = false;
        }
      });
  }

  @action showMore() {
    this.fetchOrders(this.countSkip, this.defaultTake, false, true);
  }

  @action filterBySearch(search) {
    this.filters.search = search;
    this.fetchOrders();
  }

  /* ORDER DETAIL */
  @observable selectedOrder = {};
  @observable detailIsFetched = false;

  @action fetchOrderDetail(id) {
    this.detailIsFetched = false;
    const query = apiRoutes.ORDER_DETAIL
      .replace('{orderId}', id);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.selectedOrder = response ? response.data : {};
          this.undoEdit();
          this.detailIsFetched = true;
          resolve(this.selectedOrder);
        });
    });
  }

  @observable quantity;
  @observable shippingSites = [];
  @observable notes;
  @observable addressNotes;

  @action setQuantity(quantity) {
    this.quantity = quantity;
  }

  @action setShippingSites(shippingSites) {
    this.shippingSites = shippingSites;
    const { addressFreeform } = this.selectedOrder;
    this.shippingSites = toJS(this.shippingSites)
      .sort((x, y) => (x.addressFreeform === addressFreeform
        ? -1 : (y.addressFreeform === addressFreeform ? 1 : 0)))
      .map((s) => {
        s.preferred = (s.addressFreeform === addressFreeform);
        return s;
      });
  }

  @action changeShippingSite(siteId) {
    this.shippingSites = toJS(this.shippingSites)
      .sort((x, y) => (x.id === siteId ? -1 : (y.id === siteId ? 1 : 0)))
      .map((s) => {
        s.preferred = (s.id === siteId);
        return s;
      });
  }

  @action setNotes(notes) {
    this.notes = notes;
  }

  @action setAddressExtraInfo(addressNotes) {
    this.addressNotes = addressNotes;
  }

  @observable disabledEdit = true;

  @action undoEdit() {
    this.quantity = this.selectedOrder.quantity;
    this.notes = this.selectedOrder.notes;
    this.addressNotes = this.selectedOrder.addressNotes;
    this.setShippingSites(this.shippingSites);
    this.disabledEdit = true;
  }

  @action startEdit() {
    this.undoEdit();
    this.disabledEdit = false;
  }

  @computed get disabledSave() {
    return this.notes === this.selectedOrder.notes
      && this.addressNotes === this.selectedOrder.addressNotes
      && this.shippingSites[0].addressFreeform === this.selectedOrder.addressFreeform
      && this.quantity === this.selectedOrder.quantity;
  }

  @action cleanUp() {
    this.filters = this.defaultFilters;
    this.items = [];
    this.isFetched = false;
    this.selectedOrder = {};
    this.detailIsFetched = false;
    this.downloadUrl = '';
  }

  /* NEW ORDER */
  @observable downloadUrl = '';

  @action push(newOrder) {
    api
      .post(apiRoutes.ORDERS, newOrder)
      .then((response) => {
        if (!newOrder.printRequested) {
          const query = apiRoutes.ORDER_DETAIL
            .replace('{orderId}', response.data.id);
          const loop = setInterval(() => {
            api
              .get(query)
              .then((res) => {
                const order = res.data;
                // TODO: DA CAMBIARE QUANDO AGGIUNGONO NUOVO STATO
                if (order.statusLabel === orderStatus.DOWNLOADABLE
                  && order.materialCustomizationTypeLabel === customizationTypes.AUTOMATED) {
                  clearInterval(loop);
                  if (order.materialDownloadAllowed) {
                    this.downloadUrl = buildUrl(apiRoutes.FILE.replace('{fileId}', order.customizedMaterialId));
                    downloadFile(api, this.downloadUrl, order.customizedMaterialName);
                  }
                }
              });
          }, 5000);
        }
      });
  }

  @action updateOrder() {
    const query = apiRoutes.ORDER_DETAIL
      .replace('{orderId}', this.selectedOrder.id);
    api
      .put(query, {
        ...this.selectedOrder,
        notes: this.notes,
        quantity: this.quantity,
        ...this.shippingSites[0],
        addressNotes: this.addressNotes,
      })
      .then(() => {
        this.fetchOrderDetail(this.selectedOrder.id);
      });
  }

  @action cancelOrder(motivation) {
    const query = apiRoutes.ORDER_CANCEL
      .replace('{orderId}', this.selectedOrder.id);
    api
      .put(query, { notes: motivation })
      .then(() => {
        this.fetchOrderDetail(this.selectedOrder.id);
      });
  }
}
