import React from 'react';
import Rd from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../../../commons';

const styles = {
  root: {
    color: colors.MAIN,
    opacity: 1,
    borderWidth: '2px',
    padding: 0,
    marginRight: '8px',
    marginLeft: '16px',
    '&$checked': {
      color: colors.SECONDARY,
    },
  },
  checked: {},
};

const Radio = props => (
  <RadioGroup
    value={props.value}
    onChange={props.onChange}
    className={props.className}
  >
    {props.options.map(option => (
      <FormControlLabel
        value={option.value}
        control={(
          <Rd
            disabled={props.disabled}
            classes={{
              root: props.classes.root,
              checked: props.classes.checked,
            }}
            disableRipple
          />
        )}
        label={option.label}
        key={option.label}
      />
    ))}
  </RadioGroup>
);

export default withStyles(styles)(Radio);
