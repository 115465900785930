/* eslint-disable prefer-destructuring */
import { observable, action, computed, toJS } from 'mobx';

import { apiRoutes, userRoles, api, profileStore, customizeStore, orderStore, translationStore, sequentialGet, resetTranslationStore, resetMomentLocale } from '..';

export default class UserStore {
  @observable info = {};

  @computed get isFetched() {
    return Object.keys(this.info).length !== 0
      && this.info.constructor === Object;
  }

  @computed get fullName() {
    return `${this.info.firstName} ${this.info.lastName}`;
  }

  @computed get isCommonUser() {
    return this.info.role
      && this.info.role.label === userRoles.CONTACT;
  }

  @computed get isCustomerAdmin() {
    return this.isCommonUser && this.info.isCustomerAdmin;
  }

  @computed get isReferral() {
    return this.info.customer
      && this.info.customer.categoryId === '675b5f3d-ae0a-41ac-afce-7b8caee0e94a';
  }

  @computed get isNexi() {
    return this.info.customer
      && this.info.customer.categoryId === '675b5f3d-ae0a-41ac-afce-7b8caee0e94b';
  }

  @action fetchUserInfo() {
    api
      .get(apiRoutes.USER)
      .then((response) => {
        this.info = response.data ? response.data : {};
        profileStore.setReferent(toJS(this.info));
      });
  }

  @action setUserInfo(info) {
    this.info = info;
    profileStore.setReferent(toJS(this.info));
    customizeStore.setShippingSites(this.info.branch
      ? toJS(this.info.branch.shippingSites) : []);
    orderStore.setShippingSites(this.info.branch
      ? toJS(this.info.branch.shippingSites) : []);
    resetTranslationStore(info.language ?? null);
    resetMomentLocale(info.language ? info.language.iso2 : 'en');
  }

  /* COOKIE CONSENT ----------------------------------------------------- */

  @computed get consentGiven() {
    return this.info.consentGiven;
  }

  setConsent = () => {
    api.put(apiRoutes.USER_CONSENT);
  }

  /* NOTIFICATIONS ------------------------------------------------------ */

  @observable notifications = [];

  @computed get hasNotifications() {
    return this.notifications.length > 0;
  }

  @computed get unreadNotifications() {
    return this.notifications.filter(n => !n.read).length;
  }

  @action fetchUserNotifications() {
    api
      .get(apiRoutes.USER_NOTIFICATIONS)
      .then((response) => {
        this.notifications = response.data ? response.data.data : {};
      });
  }

  @action markNotificationAsRead() {
    api
      .post(apiRoutes.USER_NOTIFICATIONS_MARKASREAD, {
        action: 3,
        data: this.notifications.map(n => n.id),
      })
      .then((response) => {
        this.notifications = response.data ? response.data.data : {};
      });
  }

  /* GESTIONE UTENTI ----------------------------------------------------- */

  @observable users = [];
  @observable loadingUsers = false;
  defaultFilters = {
    search: '',
    skip: 0,
    take: 10,
    total: 0,
    currentPage: 1,
  };
  @observable filters = this.defaultFilters;

  @action fetchUsers({ search, skip, take } = this.filters) {
    this.loadingUsers = true;

    const route = `${apiRoutes.USER_GET_POST}?search=${search}&skip=${skip}&take=${take}`;

    sequentialGet(route)
      .then(async (res) => {
        if (res) {
          this.users = res.data.data
            .map((u) => {
              u.groups = u.groups
                .map((g) => {
                  g.translatedLabel = translationStore.get(g.label);
                  return g;
                });
              return u;
            });
          if (!this.isFetchedBranches) {
            await this.fetchBranches();
          }
          if (!this.isFetchedMailingList) {
            await this.fetchMailingList();
          }
          this.filters.total = res.data.filteredCount;
          this.loadingUsers = false;
        }
      });
  }

  @computed get isFetchedUsers() {
    return !this.loadingUsers
      && this.users.length > 0;
  }

  @action filterBySearch(search) {
    this.filters.search = search;
    this.fetchUsers();
  }

  @action filterByChangePage(skip, currentPage) {
    this.filters.skip = skip;
    this.filters.currentPage = currentPage;
    this.fetchUsers();
  }

  @observable branches = [];

  @action fetchBranches() {
    return new Promise((resolve) => {
      api
        .get(apiRoutes.CUSTOMER)
        .then((res) => {
          this.branches = res.data.branches;
          this.baseUser.branch = this.branches[0];
          this.baseUser.branchId = this.branches[0].id;
          resolve(true);
        });
    });
  }

  @computed get isFetchedBranches() {
    return this.branches.length > 0;
  }

  @observable mailingList = [];

  @action fetchMailingList() {
    return new Promise((resolve) => {
      api
        .get(apiRoutes.USER_GROUPS)
        .then((res) => {
          this.mailingList = res.data.data
            .filter(g => g.label.includes('mailing_list'))
            .map((g) => {
              g.translatedLabel = translationStore.get(g.label);
              return g;
            });
          resolve(true);
        });
    });
  }

  @computed get isFetchedMailingList() {
    return this.mailingList.length > 0;
  }

  baseUser = {
    firstName: '',
    lastName: '',
    branch: {},
    branchId: '',
    email: '',
    certifiedEmail: '',
    groups: [],
    phoneNumber: '',
    mobilePhoneNumber: '',
    authenticationEnabled: false,
  };
  @observable selectedUser = this.baseUser;

  @action setSelectedUser(selectedUser = this.baseUser) {
    this.selectedUser = selectedUser;
  }

  @action setFirstName(firstName) {
    this.selectedUser.firstName = firstName;
  }

  @action setLastName(lastName) {
    this.selectedUser.lastName = lastName;
  }

  @action setBranch(branchId) {
    this.selectedUser.branch = this.branches
      .filter(b => b.id === branchId)[0];
    this.selectedUser.branchId = branchId;
  }

  @action setEmail(email) {
    this.selectedUser.email = email;
  }

  @action setCertifiedEmail(certifiedEmail) {
    this.selectedUser.certifiedEmail = certifiedEmail;
  }

  @action setMailingList(mailingList) {
    this.selectedUser.groups = mailingList;
  }

  @action setPhoneNumber(phoneNumber) {
    this.selectedUser.phoneNumber = phoneNumber;
  }

  @action setMobilePhoneNumber(mobilePhoneNumber) {
    this.selectedUser.mobilePhoneNumber = mobilePhoneNumber;
  }

  @action toggleAuthenticationEnabled() {
    this.selectedUser.authenticationEnabled = !this.selectedUser.authenticationEnabled;
  }

  @action cleanUp() {
    this.filters = this.defaultFilters;
    this.users = [];
    this.branches = [];
    this.mailingList = [];
    this.selectedUser = this.baseUser;
  }

  @action addUser(user = this.selectedUser) {
    api
      .post(apiRoutes.USER_GET_POST, user)
      .then(() => {
        this.cleanUp();
        this.fetchUsers();
      });
  }

  @action editUser(user = this.selectedUser) {
    const route = apiRoutes.USER_PUT
      .replace('{usersId}', user.id);

    api
      .put(route, user)
      .then(() => {
        this.cleanUp();
        this.fetchUsers();
      });
  }

  @action deleteUser(user = this.selectedUser) {
    const route = apiRoutes.USER_PUT
      .replace('{usersId}', user.id);

    api
      .delete(route)
      .then(() => {
        this.cleanUp();
        this.fetchUsers();
      });
  }
}
