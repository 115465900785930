import React from 'react';
import { Multiselect as Mltslct } from 'multiselect-react-dropdown';
import { observer } from 'mobx-react';

import { translationStore, labels } from '../../../commons';

// https://github.com/srigar/multiselect-react-dropdown

const Multiselect = observer(({ options, values, onChange, valueLabel }) => (
  <div className="multiselect-wrapper">
    <Mltslct
      options={options}
      selectedValues={values}
      onSelect={onChange}
      onRemove={onChange}
      displayValue={valueLabel || 'label'}
      placeholder=""
      avoidHighlightFirstOption
      closeOnSelect={false}
      closeIcon="cancel"
      emptyRecordMsg={translationStore.get(labels.NO_DATA)}
    />
  </div>
));

export default Multiselect;
