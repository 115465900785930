import { fileTypes } from '..';

export function isHtml(type) {
  return type === fileTypes.HTML;
}

export function isImage(type) {
  return type === fileTypes.JPEG
    || type === fileTypes.PNG
    || type === fileTypes.SVG;
}

export function isPdf(type) {
  return type === fileTypes.PDF;
}
