export function capitalize(str) {
  return str.replace(/\b\w/g, s => s.toUpperCase());
}

export function checkValidNumber(number) {
  return (RegExp('^(\\+\\d{1,2}\\s)?\\d{2,3}?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$').test(number) && number.length) || !number.length;
}

export function checkValidQuantity(quantity, min = null, max = null) {
  return (quantity >= 1) && (quantity <= (max || 20000)) && (quantity >= (min || 1));
}

export function checkValidYear(year) {
  return RegExp('^([0-9]{0,4})?$').test(year) && year.length;
}

export function trimContent(content, size) {
  const match = /<br>/.exec(content);
  return (content.length > size || match ? content.slice(0, (match ? match.index : size)).trim().concat('...') : content);
}
