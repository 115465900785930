import React from 'react';
import { Card as Crd, CardHeader, CardBody, Row, Col } from 'reactstrap';

import { Button } from '../..';

const Card = props => (
  <Crd className={props.className ? `${props.className} crd` : 'crd'}>
    {props.title ? (
      <CardHeader tag="h3">
        <Row>
          <Col md={props.rightLabel ? 6 : 8}>
            {props.title}
          </Col>
          <Col md={props.rightLabel ? 6 : 4} className="text-right">
            {props.rightLabel && `${props.rightLabel}: `}
            {props.rightValue && (<span>{props.rightValue}</span>)}
            {!props.btnHide && (
              <Button
                color="link"
                label={props.btnLabel}
                onClick={props.btnOnClick}
                to={props.btnTo}
                disabled={props.btnDisabled}
              />
            )}
          </Col>
        </Row>
        {props.subtitle ? (
          <span className="subtitle">
            {props.subtitle}
          </span>
        ) : ''}
      </CardHeader>
    ) : ''}
    <CardBody className={`${props.noShadow ? '' : 'shadow'}${props.visible ? ' overflow-visible' : ''}`}>
      {props.children}
    </CardBody>
  </Crd>
);

export default Card;
