import React from 'react';

const Badge = props => (
  <span
    className={`badge badge-pill ${props.className ? props.className : ''} ${props.outline ? `badge-outline-${props.color}` : ''}`}
  >
    {props.label}
    {props.icon ? <props.icon /> : ''}
  </span>
);

export default Badge;
