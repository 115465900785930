import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { routes, history } from './commons';
import { Layout } from './pages';

import 'react-quill/dist/quill.snow.css';

export default class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path={routes.ROOT} component={Layout} />
          <Route path={routes.BACK_OFFICE} />
        </Switch>
      </Router>
    );
  }
}
