import { toast } from 'react-toastify';

import { translationStore, errors } from '..';

export default class ErrorStore {
  errorSwitch = status => ({
    400: translationStore.get(errors.ERR_400),
    404: translationStore.get(errors.ERR_404),
    500: translationStore.get(errors.ERR_500),
  })[status]

  addError(error) {
    let message = error;
    if (error && error.response && error.response.data && error.response.data.message) {
      ({ message } = error.response.data);
    } else if (error && error.request && error.request.status) {
      message = this.errorSwitch(error.request.status);
    } else if (error && error.message) {
      ({ message } = error);
    }
    toast.error(`${translationStore.get(message)}`);
  }
}
