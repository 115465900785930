import { AuthStore, DeviceStore, TranslationStore, SettingStore } from '@carosello/ui-commons';

import { api, apiRoutes, system, defaultLanguages } from '..';
import CampaignStore from './CampaignStore';
import GadgetStore from './GadgetStore';
import CustomizeStore from './CustomizeStore';
import DocumentStore from './DocumentStore';
import ErrorStore from './ErrorStore';
import HelpStore from './HelpStore';
import MaterialStore from './MaterialStore';
import NavigationStore from './NavigationStore';
import NewsStore from './NewsStore';
import OrderStore from './OrderStore';
import PathStore from './PathStore';
import PreviewStore from './PreviewStore';
import ProfileStore from './ProfileStore';
import SearchStore from './SearchStore';
import UserStore from './UserStore';

export const authStore = new AuthStore(api);
export const campaignStore = new CampaignStore();
export const gadgetStore = new GadgetStore();
export const customizeStore = new CustomizeStore();
export const deviceStore = new DeviceStore();
export const documentStore = new DocumentStore();
export const errorStore = new ErrorStore();
export const helpStore = new HelpStore();
export const materialStore = new MaterialStore();
export const navigationStore = new NavigationStore();
export const newsStore = new NewsStore();
export const orderStore = new OrderStore();
export const pathStore = new PathStore();
export const previewStore = new PreviewStore();
export const profileStore = new ProfileStore();
export const searchStore = new SearchStore();
export const userStore = new UserStore();
export const settingStore = new SettingStore();

//il translation store sarà inizializzato dallo userStore (setUserInfo()) una volta caricate le informazioni dell'utente loggato
export let translationStore = null;

export const resetTranslationStore = (language = null) => {
  translationStore = new TranslationStore(
    api,
    apiRoutes.LANGUAGES,
    apiRoutes.TRANSLATIONS,
    apiRoutes.COUNTRY,
    apiRoutes.USER_LANGUAGE,
    system.PREFIX,
    language ? language : defaultLanguages.ENG,
  );
}
