import { action, observable, computed } from 'mobx';

import { api, apiRoutes, isHtml, isImage, customizeStore, materialParamCategories } from '..';

export default class MaterialStore {
  @observable selectedMaterial = {};

  @computed get detailIsFetched() {
    return Object.keys(this.selectedMaterial).length !== 0
      && this.selectedMaterial.constructor === Object;
  }

  @action fetchMaterialDetail(materialId) {
    const query = apiRoutes.MATERIAL_DETAIL
      .replace('{materialId}', materialId);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.selectedMaterial = response
            ? { ...response.data, img: this.buildTemplateUrl(response.data) }
            : {};
          resolve(this.selectedMaterial);
        });
    });
  }

  @action setParameter(parameterName, value = null) {
    this.selectedMaterial.parameters = this.selectedMaterial.parameters
      .map((parameter) => {
        if (parameter.name === parameterName) {
          switch (parameter.categoryLabel) {
            case materialParamCategories.ARTWORK:
              if (parameter.values) {
                parameter.values.map((param, i) => {
                  param.active = value
                    ? param.artworkId === value
                    : i === 0;
                  if (param.active) {
                    parameter.value = param.artworkId;
                    parameter.active = true;
                  }
                  return param;
                });
              }
              break;
            case materialParamCategories.CHECKBOX:
              parameter.active = true;
              parameter.checked = true;
              parameter.value = 'true';
              break;
            case materialParamCategories.MONEY:
              parameter.active = true;
              parameter.value = value;
              break;
            default:
              break;
          }
        }
        return parameter;
      });
    this.refreshPreview();
  }

  @action unsetParameter(parameterName) {
    if (this.detailIsFetched) {
      this.selectedMaterial.parameters = this.selectedMaterial.parameters
        .map((parameter) => {
          if (parameter.name === parameterName) {
            parameter.active = false;
            switch (parameter.categoryLabel) {
              case materialParamCategories.ARTWORK:
                parameter.values.map((value) => {
                  value.active = false;
                  return value;
                });
                parameter.value = null;
                break;
              case materialParamCategories.CHECKBOX:
                parameter.checked = false;
                parameter.value = 'false';
                break;
              case materialParamCategories.MONEY:
                parameter.active = false;
                parameter.value = 0.00;
                break;
              default:
                break;
            }
          }
          return parameter;
        });
      this.refreshPreview();
    }
  }

  @action refreshPreview() {
    this.selectedMaterial.img = this.buildTemplateUrl();
  }

  buildTemplateUrl = (material = this.selectedMaterial) => {
    const type = material.mainTemplateContentType;
    const isPdf = !(isImage(type) || isHtml(type));
    const route = isPdf
      ? apiRoutes.MATERIAL_CUSTOMIZE_PDF
      : apiRoutes.MATERIAL_CUSTOMIZE;
    const query = route
      .replace('{materialId}', material.id);

    let qs = '';
    material.parameters
      .filter(p => customizeStore.checkCustomization(p.customizationTypeLabel))
      .forEach((parameter, i) => {
        qs += i === 0 ? `?customizationType=${parameter.customizationType}` : '';
        qs += `&${parameter.name}=${parameter.value || 'null'}`;
      });
    return query.replace('{qs}', isPdf ? encodeURIComponent(qs) : qs);
  }

  @action cleanUp() {
    this.selectedMaterial = {};
  }
}
