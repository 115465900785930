import React, { Component } from 'react';
import { Loading } from '../..';

export default class Pdf extends Component {
  state = {
    src: '',
    loading: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isHtml && props.src !== state.src) {
      return ({
        src: props.src,
        loading: true,
      });
    }
    return ({});
  }

  setLoading = (loading) => {
    this.setState({ loading });
  }

  render() {
    const {
      state: { loading },
      props: { src, title },
      setLoading,
    } = this;

    return (
      <div className="pdf-wrapper">
        {loading && (
          <Loading />
        )}
        <iframe
          src={src}
          height="100%"
          width="100%"
          title={title || ''}
          onLoad={() => {
            setLoading(false);
          }}
        />
      </div>
    );
  }
}
