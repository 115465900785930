import 'core-js';
import 'classlist-polyfill';
import 'proxy-polyfill';
import 'airbnb-js-shims';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import './assets/scss/style.scss';

import App from './App';
import { unregister } from './registerServiceWorker';

ReactDOM.render((
  <BrowserRouter>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BrowserRouter>), document.getElementById('root'));

unregister();
