import React from 'react';
import Swtch from 'react-switch';

import { colors } from '../../../commons';

// https://github.com/markusenglund/react-switch

const Switch = props => (
  <Swtch
    className={`switch${props.checked ? ' checked' : ''}`}
    onChange={props.onChange}
    checked={props.checked}
    disabled={props.disabled}

    /* MEASURES */
    height={25}
    width={40}
    handleDiameter={16}

    /* COLORS */
    onColor={colors.SECONDARY_LIGHT_40}
    offColor={colors.MAIN_LIGHT_40}
    onHandleColor={colors.SECONDARY}
    offHandleColor={colors.MAIN_LIGHT_20}

    /* ICON */
    checkedIcon={false}
    uncheckedIcon={false}

    activeBoxShadow="0"
  />
);

export default Switch;
